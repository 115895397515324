import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class Footer extends Component {
	render() {
		return (
			<div>
				<Container className='text-center'>
					<div className='rn-footer'>
						<small>Copyright &copy; Terry Moving & Storage All Rights Reserved 2020</small>
					</div>
				</Container>
			</div>
		);
	}
}

export default Footer;
