import React, { Component } from 'react';

import MovingForm from './MovingForm';

import Button from '@material-ui/core/Button';

class MovingFormContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: '',
		};
		this.setTypeHandle = this.setTypeHandle.bind(this);
	}

	setTypeHandle(type) {
		console.log(type);
		console.log(this);
		this.setState({
			type: type ? type : '',
		});
	}

	render() {
		return (
			<React.Fragment>
				{/* Start Contact Page Area  */}
				<div className='rn-stepper-page  mt--30  bg_color--1'>
					{this.state.type === '' ? (
						<div className='rn_select_type text-center'>
							<h2>Cost Estimate</h2>
							<div className='rn_select_btn_wrapper'>
								<Button onClick={() => this.setTypeHandle('interstate')}>Interstate</Button>
								<Button onClick={() => this.setTypeHandle('local')}>Local</Button>
							</div>
						</div>
					) : (
						<MovingForm setTypeHandle={this.setTypeHandle} type={this.state.type} />
					)}
				</div>
			</React.Fragment>
		);
	}
}
export default MovingFormContainer;
