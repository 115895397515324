import React, { Component } from 'react';
import { Container } from 'react-bootstrap'
import Logo from '../../assets/images/logo-white.png'
class TopHeader extends Component {
	render() {
		return (
			<React.Fragment>
				{/* Start Contact Page Area  */}
				<div className='rn-header  bg_color--1'>
                    <Container>
                        <div className='rn-header-inner'>
                            <div className="rn-navbar-brand">
                                <img src={Logo} alt="logo" />
                            </div>
                            <div className="rn-navbar-right">
                                <h1>Estimated Cost of Services</h1>
                            </div>
                            </div>
                    </Container>
				</div>
			</React.Fragment>
		);
	}
}
export default TopHeader;
