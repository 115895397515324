import React, { Component, Fragment } from 'react';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import NumberFormat from 'react-number-format';
import { Form, Table, Container, Row, Col } from 'react-bootstrap';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import bedroom from '../../assets/images/bedroom.svg';
import diningRoom from '../../assets/images/dining-room.svg';
import garage from '../../assets/images/garage.svg';
import kitchen from '../../assets/images/kitchen.svg';
import laundry from '../../assets/images/laundry.svg';
import livingRoom from '../../assets/images/living-room.svg';
import nursery from '../../assets/images/nursery.svg';
import office from '../../assets/images/office.svg';
import patio from '../../assets/images/patio.svg';
import axios from 'axios';
import Swal from 'sweetalert2';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const { GoogleSpreadsheet } = require('google-spreadsheet');

// spreadsheet key is the long id in the sheets URL
const doc = new GoogleSpreadsheet('1Jc7HBOYYnN4e9TW1_SVe7ZattHvP4V54FLeJkT6d_5g');

const styles = (theme) => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	btnBack: {
		background: 'transparent',
		'&:disabled': {
			background: 'transparent',
			cursor: 'pointer',
		},
	},
	CP: {
		padding: '0 2vh !important',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper1: {
		borderRadius: '10px',
		backgroundColor: 'white',
		boxShadow: 'black 0px 0px 20px 5px',
		width: '100vh',
		height: '20vh',
	},
	paper2: {
		borderRadius: '10px',
		backgroundColor: 'white',
		boxShadow: 'black 0px 0px 20px 5px',
		width: '100vh',
		height: '40vh',
	},
	paperCareer: {
		borderRadius: '10px',
		boxShadow: 'black 0px 0px 20px 5px',
		backgroundColor: 'white',
		width: '100vh',
		height: '50vh',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: 'bolder',
	},
	ColumnTitle: {
		fontSize: '28px',
		padding: '1vh',
		textAlign: 'right',
		borderBottom: '1px solid',
	},
	TotalTxt: {
		fontWeight: 'bold',
		fontSize: '22px',
		textTransform: 'uppercase',
	},
	ColumnResult: {
		textAlign: 'right',
		fontWeight: 'bold',
		fontSize: '22px',
		background: '#ccc',
		'& strong': {
			padding: '0 1vh',
		},
	},
	Result: {
		background: '#ccc',
	},
	GrandTotal: {
		background: '#035f9e',
		padding: '2vh',
		position: 'sticky',
		bottom: '0',
		width: '100%',
		zIndex: '999',
		'& span': {
			color: '#fff',
			fontSize: '22px',
			fontWeight: 'bold',
			display: 'flex',
			alignItems: 'start',
			justifyContent: 'flex-end',
		},
	},
	TBD__wrapper: {
		display: 'flex !important',
		alignItems: 'center',
		fontWeight: 'bold',
		'& input': {
			width: 'auto',
			marginRight: '.5rem',
		},
	},
	bottomRibbon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		'& input': {
			flex: '.4',
			background: 'rgba(255, 255, 255, 0.6)',
			margin: '0 .5rem',
		},
	},
});

class MovingFrom extends Component {
	constructor(props) {
		super(props);
		this.state = {
			costType: this.props.type,
			sendEmail: '',
			link: '',
			activeStep: 0,
			confirmReset: false,
			activatedStep: [true, false, false, false, false, false],
			steps: this.props.type && this.props.type === 'local' ? ['Client Information', 'Moving From', 'Moving To', 'Packing & Packing Supplies', 'Cost Estimate'] : ['Client Information', 'Moving From', 'Moving To', 'Packing & Packing Supplies', 'Inventory', 'Cost Estimate'],
			specialDiscount: '',
			clientInfo: {
				customerName: '',
				email: '',
				repEmail: '',
				phoneNo: '',
			},

			movingFromDetails: {
				movingFromDate: '',
				origin1: {
					streetAddress: '',
					city: '',
					state: '',
					zip: '',
				},
				origin2: {
					description: '',
					streetAddress: '',
					city: '',
					state: '',
					zip: '',
				},
				notes: '',
				originInformation: {
					sqft: '',
					bedrooms: '',
					livingRooms: '',
					diningRooms: '',
					garage: '',
					patio: '',
					offices: '',
					bonusRooms: '',
					familyRooms: '',
					floors: [
						{ title: '1st Floor', value: false },
						{ title: '2nd Floor', value: false },
						{ title: '3rd Floor', value: false },
					],
					appliances: [
						{ title: 'None', value: false },
						{ title: 'Refrigerator', value: false },
						{ title: 'Stove/Oven', value: false },
						{ title: 'Special Pieces', value: false },
						{ title: 'Washer', value: false },
						{ title: 'Dryer', value: false },
						{ title: 'Dishwasher', value: false },
						{ title: 'Other', value: false },
					],
				},
			},
			movingToDetails: {
				delieveryDate: '',
				sameAsOrigin: false,
				tbd: false,
				destination1: {
					contactName: '',
					phoneNumber: '',
					description: '',
					streetAddress: '',
					city: '',
					state: '',
					zip: '',
					floors: [
						{ title: '1st Floor', value: false },
						{ title: '2nd Floor', value: false },
						{ title: '3rd Floor', value: false },
					],
				},
				destination2: {
					contactName: '',
					phoneNumber: '',
					description: '',
					streetAddress: '',
					city: '',
					state: '',
					zip: '',
					floors: [
						{ title: '1st Floor', value: false },
						{ title: '2nd Floor', value: false },
						{ title: '3rd Floor', value: false },
					],
				},
				notes: '',
			},
			packingAndPackingSupplies: {
				packingDate: '',
				items: [
					{
						item: 'Dish',
						pricePer: '$8',
						price: 8,
						qty: '',
						cost: '',
					},
					{
						item: '1.5/Small Box',
						pricePer: '$2.50',
						price: 2.5,
						qty: '',
						cost: '',
					},
					{
						item: '3.0/Med Box',
						pricePer: '$3.50',
						price: 3.5,
						qty: '',
						cost: '',
					},
					{
						item: '4.5/Large Box',
						pricePer: '$4.50',
						price: 4.5,
						qty: '',
						cost: '',
					},
					{
						item: '6.0/Ex Large',
						pricePer: '$5.00',
						price: 5.0,
						qty: '',
						cost: '',
					},
					{
						item: 'Wardrobe 20',
						pricePer: '$15 with the bar',
						price: 15,
						qty: '',
						cost: '',
					},
					{
						item: 'Mirror Pack',
						pricePer: '$20',
						price: 20,
						qty: '',
						cost: '',
					},
					{
						item: 'News Packing Paper',
						pricePer: '$30/Roll 30lbs',
						price: 30,
						qty: '',
						cost: '',
					},
					{
						item: 'Tape',
						pricePer: '$3.95/roll',
						price: 3.95,
						qty: '',
						cost: '',
					},
					// {
					//   item: "Labor",
					//   pricePer: "",
					//   price: 0,
					//   qty: "",
					//   cost: "",
					// },
				],
				totalSupplies: 0,
				totalPrice: 0,
				totalLabor: 0,
			},
			costEstimate: {
				items: [
					// {
					//   title: "Packing Supplies",
					//   team: "",
					//   miles: "",
					//   hours: "",
					//   pricePer: "",
					//   cost: "0",
					// },
					{
						title: 'Packing Labor',
						team: '',
						miles: '',
						hours: '',
						pricePer: '',
						cost: '0',
					},
					{
						title: 'Moving (Miles per 100 lbs)',
						team: '',
						miles: '',
						weight: '',
						hours: '',
						pricePer: '',
						cost: '0',
					},
					{
						title: 'Moving (Labor)',
						team: '',
						miles: '',
						hours: '',
						pricePer: '',
						cost: '0',
					},
					{
						title: 'Valuation (Value of goods shipped)',
						team: '',
						miles: '',
						hours: '',
						pricePer: '',
						cost: '0',
					},
					{
						title: 'Packing Supplies',
						team: '',
						miles: '',
						hours: '',
						pricePer: '',
						cost: '0',
					},
					{
						title: 'Additional Transportation Cost',
						team: '',
						miles: '',
						hours: '',
						pricePer: '',
						cost: '0',
					},
					{
						title: 'Additional Cost',
						team: '',
						miles: '',
						hours: '',
						pricePer: '',
						cost: '0',
					},
					{
						title: 'Custom Moving Team',
						team: '',
						miles: '',
						hours: '',
						pricePer: '',
						cost: '0',
					},
					{
						title: 'Custom Packing Team',
						team: '',
						miles: '',
						hours: '',
						pricePer: '',
						cost: '0',
					},
				],
				additionalTransportCost: 0,
				additionalCost: 0,
				costEstimateTotal: 0.0,
			},

			items: {
				Bedrooms: {
					items: [
						{
							title: 'AC (Window)',
							qty: '',
							weight: '140',
						},
						{
							title: 'Armoire',
							qty: '',
							weight: '210',
						},
						{
							title: 'Basket (Clothes)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Bed (Bunk Set (2))',
							qty: '',
							weight: '490',
						},
						{
							title: 'Bed (Double/Full)',
							qty: '',
							weight: '420',
						},
						{
							title: 'Bed (Hideaway)',
							qty: '',
							weight: '280',
						},
						{
							title: 'Bed (King)',
							qty: '',
							weight: '490',
						},
						{
							title: 'Bed (Queen)',
							qty: '',
							weight: '450',
						},
						{
							title: 'Bed (Rollaway)',
							qty: '',
							weight: '140',
						},
						{
							title: 'Bed (Single)',
							qty: '',
							weight: '280',
						},
						{
							title: 'Bed (Trundle)',
							qty: '',
							weight: '350',
						},
						{
							title: 'Bookshelves (Sect)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Bureau (Dresser)',
							qty: '',
							weight: '210',
						},
						{
							title: 'Cedar Chest',
							qty: '',
							weight: '105',
						},
						{
							title: 'Chaise Lounge',
							qty: '',
							weight: '175',
						},
						{
							title: 'Chest (Boudoir)',
							qty: '',
							weight: '70',
						},
						{
							title: 'Daybed',
							qty: '',
							weight: '210',
						},
						{
							title: 'Desk (Small)',
							qty: '',
							weight: '154',
						},
						{
							title: 'Drawers',
							qty: '',
							weight: '175',
						},
						{
							title: 'Dresser (Bench)',
							qty: '',
							weight: '21',
						},
						{
							title: 'Dresser (Double)',
							qty: '',
							weight: '350',
						},
						{
							title: 'Lamp (Floor/Pole)',
							qty: '',
							weight: '21',
						},
						{
							title: 'Night Table',
							qty: '',
							weight: '35',
						},
						{
							title: 'Rug (Large/Pad)',
							qty: '',
							weight: '70',
						},
						{
							title: 'Rug (Small/Pad)',
							qty: '',
							weight: '21',
						},
						{
							title: 'Valet',
							qty: '',
							weight: '21',
						},
						{
							title: 'Vanity Dresser',
							qty: '',
							weight: '140',
						},
						{
							title: 'Wardrobe Cartons/Clothes',
							qty: '',
							weight: '45',
						},
						{
							title: 'Wardrobe (Large)',
							qty: '',
							weight: '280',
						},
						{
							title: 'Wardrobe (Small)',
							qty: '',
							weight: '140',
						},
						{
							title: 'Water Bed',
							qty: '',
							weight: '420',
						},
						{
							title: 'Custom Items',
							itemDescription: '',
							qty: '',
							weight: '',
						},
					],
					totalWeight: '',
					totalQty: '',
					imageUrl: bedroom,
				},
				'Dining Room': {
					items: [
						{
							title: 'Bench',
							qty: '',
							weight: '70',
						},
						{
							title: 'Buffet',
							qty: '',
							weight: '210',
						},
						{
							title: 'Cabinet (China)',
							qty: '',
							weight: '175',
						},
						{
							title: 'Cabinet (Corner)',
							qty: '',
							weight: '140',
						},
						{
							title: 'Cabinet (Curio)',
							qty: '',
							weight: '105',
						},
						{
							title: 'Chair (Dining)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Hutch (Bottom)',
							qty: '',
							weight: '140',
						},
						{
							title: 'Hutch (Top)',
							qty: '',
							weight: '210',
						},
						{
							title: 'Rug (Large/Pad)',
							qty: '',
							weight: '70',
						},
						{
							title: 'Rug (Small/Pad)',
							qty: '',
							weight: '210',
						},
						{
							title: 'Server',
							qty: '',
							weight: '105',
						},
						{
							title: 'Table (Dining)',
							qty: '',
							weight: '210',
						},
						{
							title: 'Tea Cart',
							qty: '',
							weight: '70',
						},
						{
							title: 'Custom Items',
							itemDescription: '',
							qty: '',
							weight: '',
						},
					],
					totalWeight: '',
					totalQty: '',
					imageUrl: diningRoom,
				},
				Garage: {
					items: [
						{
							title: 'Ash/Trash Can',
							qty: '',
							weight: '49',
						},
						{
							title: 'Barbell Set (Small)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Bicycle',
							qty: '',
							weight: '35',
						},
						{
							title: 'Bowling Ball/Bag',
							qty: '',
							weight: '14',
						},
						{
							title: 'Cabinet (File)',
							qty: '',
							weight: '140',
						},
						{
							title: 'Car Carrier',
							qty: '',
							weight: '70',
						},
						{
							title: 'Card Table',
							qty: '',
							weight: '7',
						},
						{
							title: 'Chair (Folding)',
							qty: '',
							weight: '7',
						},
						{
							title: 'Cot (Folding)',
							qty: '',
							weight: '70',
						},
						{
							title: 'Dehumidifier',
							qty: '',
							weight: '70',
						},
						{
							title: 'Exercise Bike',
							qty: '',
							weight: '105',
						},
						{
							title: 'Fan',
							qty: '',
							weight: '35',
						},
						{
							title: 'Floor Polisher',
							qty: '',
							weight: '21',
						},
						{
							title: 'Footlocker/Trunk',
							qty: '',
							weight: '35',
						},
						{
							title: 'Freezer (15 CU or Less)',
							qty: '',
							weight: '315',
						},
						{
							title: 'Freezer (16 CU or More)',
							qty: '',
							weight: '420',
						},
						{
							title: 'Golf Bag',
							qty: '',
							weight: '14',
						},
						{
							title: 'Heater (Gas/Electric)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Humidifier',
							qty: '',
							weight: '35',
						},
						{
							title: 'Metal Shelves/Section',
							qty: '',
							weight: '35',
						},
						{
							title: 'Mini-Refrigerator',
							qty: '',
							weight: '70',
						},
						{
							title: 'Moped',
							qty: '',
							weight: '105',
						},
						{
							title: 'Motor (Electric)',
							qty: '',
							weight: '7',
						},
						{
							title: 'Movie Screen',
							qty: '',
							weight: '7',
						},
						{
							title: 'Movie/Slide Projector',
							qty: '',
							weight: '7',
						},
						{
							title: 'Ping Pong Table',
							qty: '',
							weight: '140',
						},
						{
							title: 'Pool Table',
							qty: '',
							weight: '280',
						},
						{
							title: 'Power Tools',
							qty: '',
							weight: '140',
						},
						{
							title: 'Rowing Machine',
							qty: '',
							weight: '70',
						},
						{
							title: 'Saw Horse',
							qty: '',
							weight: '35',
						},
						{
							title: 'Scooter',
							qty: '',
							weight: '35',
						},
						{
							title: 'Sewing Machine',
							qty: '',
							weight: '70',
						},
						{
							title: 'Skis/Poles',
							qty: '',
							weight: '21',
						},
						{
							title: 'Sled',
							qty: '',
							weight: '14',
						},
						{
							title: 'Sleeping Bag',
							qty: '',
							weight: '7',
						},
						{
							title: 'Step Ladder',
							qty: '',
							weight: '35',
						},
						{
							title: 'Suitcase',
							qty: '',
							weight: '35',
						},
						{
							title: 'Table (Utility)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Tackle Box',
							qty: '',
							weight: '7',
						},
						{
							title: 'Tool Chest',
							qty: '',
							weight: '70',
						},
						{
							title: 'Treadmill',
							qty: '',
							weight: '50',
						},
						{
							title: 'Tricycle',
							qty: '',
							weight: '35',
						},
						{
							title: 'Vacuum Cleaner',
							qty: '',
							weight: '35',
						},
						{
							title: "Wagon (Child's)",
							qty: '',
							weight: '35',
						},
						{
							title: 'Weight Bench',
							qty: '',
							weight: '105',
						},
						{
							title: 'Work Bench',
							qty: '',
							weight: '140',
						},
						{
							title: 'Work Mate',
							qty: '',
							weight: '70',
						},
						{
							title: 'Custom Items',
							itemDescription: '',
							qty: '',
							weight: '',
						},
					],
					totalWeight: '',
					totalQty: '',
					imageUrl: garage,
				},
				Kitchen: {
					items: [
						{
							title: 'Breakfast Set (Chairs)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Breakfast Set (Table)',
							qty: '',
							weight: '70',
						},
						{
							title: 'Bulletin Board',
							qty: '',
							weight: '7',
						},
						{
							title: 'Chair (High)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Dish Set',
							qty: '',
							weight: '70',
						},
						{
							title: 'Dishwasher',
							qty: '',
							weight: '140',
						},
						{
							title: 'Kitchen Cabinet',
							qty: '',
							weight: '210',
						},
						{
							title: 'Metal Cart',
							qty: '',
							weight: '35',
						},
						{
							title: 'Microwave',
							qty: '',
							weight: '70',
						},
						{
							title: 'Microwave Cart',
							qty: '',
							weight: '70',
						},
						{
							title: 'Pots and Pans',
							qty: '',
							weight: '35',
						},
						{
							title: 'Range',
							qty: '',
							weight: '210',
						},
						{
							title: 'Refrigerator (10 cu. ft. or Less)',
							qty: '',
							weight: '315',
						},
						{
							title: 'Refrigerator (11 cu. ft. or More)',
							qty: '',
							weight: '420',
						},
						{
							title: 'Roaster',
							qty: '',
							weight: '35',
						},
						{
							title: 'Serving Cart',
							qty: '',
							weight: '105',
						},
						{
							title: 'Serving Dishes',
							qty: '',
							weight: '5',
						},
						{
							title: 'Silverware',
							qty: '',
							weight: '35',
						},

						{
							title: 'Stand Mixer',
							qty: '',
							weight: '20',
						},
						{
							title: 'Stool',
							qty: '',
							weight: '21',
						},
						{
							title: 'Utility Cabinet',
							qty: '',
							weight: '70',
						},

						{
							title: 'Custom Items',
							itemDescription: '',
							qty: '',
							weight: '',
						},
					],
					totalWeight: '',
					totalQty: '',
					imageUrl: kitchen,
				},
				Laundry: {
					items: [
						{
							title: 'Basket (Clothes)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Clothes Dry Rack',
							qty: '',
							weight: '35',
						},
						{
							title: 'Clothes Hamper',
							qty: '',
							weight: '35',
						},
						{
							title: 'Dryer',
							qty: '',
							weight: '175',
						},
						{
							title: 'Ironing Board',
							qty: '',
							weight: '21',
						},
						{
							title: 'Rug (Small/Pad)',
							qty: '',
							weight: '21',
						},
						{
							title: 'Washing Machine',
							qty: '',
							weight: '175',
						},

						{
							title: 'Custom Items',
							itemDescription: '',
							qty: '',
							weight: '',
						},
					],
					totalWeight: '',
					totalQty: '',
					imageUrl: laundry,
				},
				'Living / Family Rooms': {
					items: [
						{
							title: 'Bar Cart',
							qty: '',
							weight: '105',
						},
						{
							title: 'Bench (Piano)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Bird Cage/Stand',
							qty: '',
							weight: '35',
						},
						{
							title: 'Book Case (Small)',
							qty: '',
							weight: '70',
						},
						{
							title: 'Book Case/Wall Unit',
							qty: '',
							weight: '140',
						},
						{
							title: 'Books',
							qty: '',
							weight: '45',
						},
						{
							title: 'Bookshelves (Sect)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Chair (Arm)',
							qty: '',
							weight: '70',
						},
						{
							title: 'Chair (Occas)',
							qty: '',
							weight: '105',
						},
						{
							title: 'Chair (Overstuffed)',
							qty: '',
							weight: '175',
						},
						{
							title: 'Chair (Straight)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Clock (Grandfather)',
							qty: '',
							weight: '140',
						},
						{
							title: 'Desk (Small)',
							qty: '',
							weight: '154',
						},
						{
							title: 'Fireplace Equipment',
							qty: '',
							weight: '35',
						},
						{
							title: 'Fish Tank',
							qty: '',
							weight: '105',
						},
						{
							title: 'Footstool',
							qty: '',
							weight: '14',
						},
						{
							title: 'Lamp (Floor/Pole)',
							qty: '',
							weight: '21',
						},
						{
							title: 'Magazine Rack',
							qty: '',
							weight: '14',
						},
						{
							title: 'Piano (Baby Grand)',
							qty: '',
							weight: '490',
						},
						{
							title: 'Piano (Parlor Grand)',
							qty: '',
							weight: '560',
						},
						{
							title: 'Piano (Spinet)',
							qty: '',
							weight: '420',
						},
						{
							title: 'Pictures',
							qty: '',
							weight: '25',
						},
						{
							title: 'Radio (Table)',
							qty: '',
							weight: '14',
						},
						{
							title: 'Record Player',
							qty: '',
							weight: '14',
						},
						{
							title: 'Rug (Large/Pad)',
							qty: '',
							weight: '70',
						},
						{
							title: 'Rug (Small/Pad)',
							qty: '',
							weight: '21',
						},
						{
							title: 'Sofa (2 Cushion)',
							qty: '',
							weight: '245',
						},
						{
							title: 'Sofa (3 Cushion)',
							qty: '',
							weight: '315',
						},
						{
							title: 'Sofa (4 Cushion)',
							qty: '',
							weight: '420',
						},
						{
							title: 'Sofa (Section Per)',
							qty: '',
							weight: '210',
						},
						{
							title: 'Speaker (Stereo)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Studio (Hidebed)',
							qty: '',
							weight: '350',
						},
						{
							title: 'TV Big Screen',
							qty: '',
							weight: '100',
						},
						{
							title: 'TV Console',
							qty: '',
							weight: '105',
						},
						{
							title: 'TV Stand',
							qty: '',
							weight: '35',
						},
						{
							title: 'TV Tray Set',
							qty: '',
							weight: '21',
						},
						{
							title: 'Table (Coffee/End)',
							qty: '',
							weight: '35',
						},
						{
							title: 'Table (Dropleaf)',
							qty: '',
							weight: '84',
						},
						{
							title: 'Video Game Set',
							qty: '',
							weight: '35',
						},
						{
							title: 'Wall Art',
							qty: '',
							weight: '15',
						},
						{
							title: 'Wine Rack',
							qty: '',
							weight: '35',
						},

						{
							title: 'Custom Items',
							itemDescription: '',
							qty: '',
							weight: '',
						},
					],
					totalWeight: '',
					totalQty: '',
					imageUrl: livingRoom,
				},
				Nursery: {
					items: [
						{
							title: 'Baby Carriage',
							qty: '',
							weight: '70',
						},

						{ title: 'Basket (Plastic)', qty: '', weight: '21' },
						{ title: 'Bassinet', qty: '', weight: '35' },
						{ title: 'Bed (Youth)', qty: '', weight: '70' },

						{ title: 'Car Seat', qty: '', weight: '14' },

						{ title: "Chair (Child's)", qty: '', weight: '21' },

						{ title: 'Chair (Rocker)', qty: '', weight: '84' },

						{ title: 'Changing Table', qty: '', weight: '70' },

						{ title: 'Chest', qty: '', weight: '91' },

						{ title: 'Chest (Toy)', qty: '', weight: '35' },

						{ title: 'Crib (Baby)', qty: '', weight: '70' },

						{ title: 'Doll House (Small)', qty: '', weight: '14' },

						{ title: 'Play House', qty: '', weight: '70' },

						{ title: 'Play Pen', qty: '', weight: '70' },
						{ title: 'Rug (Large/Pad)', qty: '', weight: '70' },

						{ title: 'Rug (Small/Pad)', qty: '', weight: '21' },

						{ title: 'Stroller', qty: '', weight: '35' },

						{ title: "Table (Child's)", qty: '', weight: '35' },

						{
							title: 'Custom Items',
							itemDescription: '',
							qty: '',
							weight: '',
						},
					],
					totalWeight: '',
					totalQty: '',
					imageUrl: nursery,
				},
				Office: {
					items: [
						{ title: 'Books', qty: '', weight: '45' },

						{ title: 'Bookshelves (Sect)', qty: '', weight: '35' },

						{ title: 'Chair (Straight)', qty: '', weight: '35' },

						{ title: 'Computer and Accessories', qty: '', weight: '35' },

						{ title: 'Desk (Office)', qty: '', weight: '210' },

						{ title: 'Desk (Secretary)', qty: '', weight: '245' },
						{ title: 'Home Computer', qty: '', weight: '30' },

						{ title: 'Printer/Keyboard', qty: '', weight: '5' },
						{ title: 'Waste Paper Basket', qty: '', weight: '2' },
						{
							title: 'Custom Items',
							itemDescription: '',
							qty: '',
							weight: '',
						},
					],
					totalWeight: '',
					totalQty: '',
					imageUrl: office,
				},

				'Patio & Shed': {
					items: [
						{ title: 'Bird Bath', qty: '', weight: '35' },
						{ title: 'Camp Stove', qty: '', weight: '14' },
						{ title: 'Chair (Outdoor)', qty: '', weight: '70' },
						{ title: "Child's Pool", qty: '', weight: '84' },
						{ title: 'Dog House', qty: '', weight: '70' },
						{ title: 'Garden Hose/Tools', qty: '', weight: '70' },
						{ title: 'Glider/Settee', qty: '', weight: '140' },
						{ title: 'Grill', qty: '', weight: '70' },
						{ title: 'Hand truck/Dolly', qty: '', weight: '14' },
						{ title: 'Ladder (Extension)', qty: '', weight: '70' },
						{ title: 'Lawn Edger', qty: '', weight: '35' },
						{ title: 'Lawn Mower (Hand)', qty: '', weight: '35' },
						{ title: 'Lawn Mower (Power)', qty: '', weight: '105' },
						{ title: 'Lawn Mower (Riding)', qty: '', weight: '245' },
						{ title: 'Leaf Sweeper', qty: '', weight: '35' },
						{ title: 'Outdoor Slide', qty: '', weight: '70' },
						{ title: 'Outdoor Swings', qty: '', weight: '105' },
						{ title: 'Picnic Bench', qty: '', weight: '35' },
						{ title: 'Picnic Table', qty: '', weight: '140' },
						{ title: 'Plant Stand', qty: '', weight: '70' },
						{ title: 'Rocker (Swing)', qty: '', weight: '105' },
						{ title: 'Roller (Lawn)', qty: '', weight: '105' },
						{ title: 'Sand Box', qty: '', weight: '70' },
						{ title: 'Settee', qty: '', weight: '140' },
						{ title: 'Snow Blower', qty: '', weight: '105' },
						{ title: 'Spreader', qty: '', weight: '7' },
						{ title: 'TV Antenna', qty: '', weight: '35' },
						{ title: 'Table (Picnic)', qty: '', weight: '70' },
						{ title: 'Umbrella', qty: '', weight: '35' },
						{ title: 'Wheelbarrow', qty: '', weight: '56' },
						{
							title: 'Custom Items',
							itemDescription: '',
							qty: '',
							weight: '',
						},
					],
					totalWeight: '',
					totalQty: '',
					imageUrl: patio,
				},
			},
			grandTotal: 0,
			grandQty: 0,
			pdfLoader: false,
			emailLoader: false,
		};
	}

	handleNext = async () => {
		if (this.state.activeStep === 0) {
			console.log(this.state.clientInfo);
		}
		if (this.state.activeStep === 1) {
			console.log(this.state.movingFromDetails);
		}
		if (this.state.activeStep === 2) {
			console.log(this.state.movingToDetails);
		}
		if (this.state.activeStep === 3) {
			console.log(this.state.packingAndPackingSupplies);
			console.log(this.state.costEstimate);
			this.state.costEstimate.items[4].cost = this.state.packingAndPackingSupplies.totalPrice;
		}
		if (this.state.activeStep === 4) {
			try {
				// use service account creds
				await doc.useServiceAccountAuth(require('../../terry-moving-forms-5bf676207530.json'));
				// doc.useApiKey('AIzaSyAe-RvE9UpwF-SNkKvlpt4YpjOgDfaUCpQ');
				await doc.loadInfo(); // loads document properties and worksheets
				// console.log(doc.title);
				// console.log(doc.sheetsByIndex[doc.sheetsByIndex.length - 1])
				// console.log(doc.sheetsByIndex[doc.sheetsByIndex.length - 1]._rawProperties.sheetId);
				const sheetId = doc.sheetsByIndex[doc.sheetsByIndex.length - 1]._rawProperties.sheetId;
				await doc.updateProperties({ title: 'Terry Moving' });
				// create a sheet and set the header row

				// const sheet = await doc.addSheet({
				// 	title: this.state.clientInfo.email,
				// 	headerValues: ['Full Name', 'Email Address', 'Phone Number', 'Moving Date', 'Origin Address', 'Delivery Date', 'Destination Address', 'Packing Date'],
				// });
				const sheet = doc.sheetsByIndex[doc.sheetsByIndex.length - 1];
				await sheet.setHeaderRow(['Full Name', 'Email Address', 'Phone Number', 'Moving Date', 'Origin Address', 'Delivery Date', 'Destination Address', 'Packing Date']);
				// console.log(sheet)
				const data = await sheet.addRow({
					'Full Name': this.state.clientInfo.customerName,
					'Email Address': this.state.clientInfo.email,
					'Phone Number': this.state.clientInfo.phoneNo,
					'Moving Date': this.state.movingFromDetails.movingFromDate,
					'Origin Address': `${this.state.movingFromDetails.origin1.streetAddress}, ${this.state.movingFromDetails.origin1.city}, ${this.state.movingFromDetails.origin1.state}, ${this.state.movingFromDetails.origin1.zip}`,
					'Delivery Date': this.state.movingToDetails.delieveryDate,
					'Destination Address': `${this.state.movingToDetails.destination1.streetAddress}, ${this.state.movingToDetails.destination1.city}, ${this.state.movingToDetails.destination1.state}, ${this.state.movingToDetails.destination1.zip}`,
					'Packing Date': this.state.packingAndPackingSupplies.packingDate,
				});
				// read rows
				const rows = await sheet.getRows(); // can pass in { limit, offset }
				console.log(rows);
				// read/write row values
				// console.log(rows[0].name); // 'Terry Moving'
				console.log('https://docs.google.com/spreadsheets/d/1Jc7HBOYYnN4e9TW1_SVe7ZattHvP4V54FLeJkT6d_5g/edit#gid=' + sheetId);
				await this.setState({
					link: 'https://docs.google.com/spreadsheets/d/1Jc7HBOYYnN4e9TW1_SVe7ZattHvP4V54FLeJkT6d_5g/edit#gid=' + sheetId,
				});
				// console.log(this.state);
				this.state.costEstimate.items[4].cost = this.state.packingAndPackingSupplies.totalPrice;
			} catch (err) {
				console.log(err);
				Swal.fire('This email already exists', 'Please use another email', 'error');
				return;
			}
		}
		// console.log(this.state);
		this.setState((prevState) => {
			let prevAS = [...prevState.activatedStep];
			prevAS[prevState.activeStep + 1] = true;
			return {
				activatedStep: prevAS,
				activeStep: prevState.activeStep + 1,
			};
		});
	};

	checkEmptyValue = (movingFromDetails) => {
		for (let i in this.state[movingFromDetails]) {
			if (typeof i === 'string') {
				if (i === '') {
					return false;
				} else {
					return true;
				}
			}
		}
	};

	handleBack = () => {
		this.setState((prevState) => {
			return {
				activeStep: prevState.activeStep - 1,
			};
		});
	};

	handleReset = () => {
		this.setState(
			(this.state = {
				link: '',
				activeStep: 0,
				confirmReset: false,
				activatedStep: [true, false, false, false, false, false],
				steps: ['Client Information', 'Moving From', 'Moving To', 'Packing & Packing Supplies', 'Inventory', 'Cost Estimate'],

				clientInfo: {
					customerName: '',
					email: '',
					repEmail: '',
					phoneNo: '',
				},

				movingFromDetails: {
					movingFromDate: '',
					origin1: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
					},
					origin2: {
						description: '',
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
					},
					notes: '',
					originInformation: {
						sqft: '',
						bedrooms: '',
						livingRooms: '',
						diningRooms: '',
						garage: '',
						patio: '',
						offices: '',
						bonusRooms: '',
						familyRooms: '',
						floors: [
							{ title: '1st Floor', value: false },
							{ title: '2nd Floor', value: false },
							{ title: '3rd Floor', value: false },
						],
						appliances: [
							{ title: 'None', value: false },
							{ title: 'Refrigerator', value: false },
							{ title: 'Stove/Oven', value: false },
							{ title: 'Special Pieces', value: false },
							{ title: 'Washer', value: false },
							{ title: 'Dryer', value: false },
							{ title: 'Dishwasher', value: false },
							{ title: 'Other', value: false },
						],
					},
				},
				movingToDetails: {
					delieveryDate: '',
					sameAsOrigin: false,
					destination1: {
						contactName: '',
						phoneNumber: '',
						description: '',
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
						floors: [
							{ title: '1st Floor', value: false },
							{ title: '2nd Floor', value: false },
							{ title: '3rd Floor', value: false },
						],
					},
					destination2: {
						contactName: '',
						phoneNumber: '',
						description: '',
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
						floors: [
							{ title: '1st Floor', value: false },
							{ title: '2nd Floor', value: false },
							{ title: '3rd Floor', value: false },
						],
					},
					notes: '',
				},
				packingAndPackingSupplies: {
					packingDate: '',
					items: [
						{
							item: 'Dish',
							pricePer: '$8',
							price: 8,
							qty: '',
							cost: '',
						},
						{
							item: '1.5/Small Box',
							pricePer: '$2.50',
							price: 2.5,
							qty: '',
							cost: '',
						},
						{
							item: '3.0/Med Box',
							pricePer: '$3.50',
							price: 3.5,
							qty: '',
							cost: '',
						},
						{
							item: '4.5/Large Box',
							pricePer: '$4.50',
							price: 4.5,
							qty: '',
							cost: '',
						},
						{
							item: '6.0/Ex Large',
							pricePer: '$5.00',
							price: 5.0,
							qty: '',
							cost: '',
						},
						{
							item: 'Wardrobe 20',
							pricePer: '$15 with the bar',
							price: 15,
							qty: '',
							cost: '',
						},
						{
							item: 'Mirror Pack',
							pricePer: '$20',
							price: 20,
							qty: '',
							cost: '',
						},
						{
							item: 'News Packing Paper',
							pricePer: '$30/Roll 30lbs',
							price: 30,
							qty: '',
							cost: '',
						},
						{
							item: 'Tape',
							pricePer: '$3.95/roll',
							price: 3.95,
							qty: '',
							cost: '',
						},
						// {
						//   item: "Labor",
						//   pricePer: "",
						//   price: 0,
						//   qty: "",
						//   cost: "",
						// },
					],
					totalSupplies: 0,
					totalPrice: 0,
					totalLabor: 0,
				},
				costEstimate: {
					items: [
						// {
						//   title: "Packing Supplies",
						//   team: "",
						//   miles: "",
						//   hours: "",
						//   pricePer: "",
						//   cost: "0",
						// },
						{
							title: 'Packing Labor',
							team: '',
							miles: '',
							hours: '',
							pricePer: '',
							cost: '0',
						},
						{
							title: 'Moving (Miles per 100 lbs)',
							team: '',
							miles: '',
							weight: '',
							hours: '',
							pricePer: '',
							cost: '0',
						},
						{
							title: 'Moving (Labor)',
							team: '',
							miles: '',
							hours: '',
							pricePer: '',
							cost: '0',
						},
						{
							title: 'Valuation (Value of goods shipped)',
							team: '',
							miles: '',
							hours: '',
							pricePer: '',
							cost: '0',
						},
						{
							title: 'Packing Supplies',
							team: '',
							miles: '',
							hours: '',
							pricePer: '',
							cost: '0',
						},
						{
							title: 'Additional Transportation Cost',
							team: '',
							miles: '',
							hours: '',
							pricePer: '',
							cost: '0',
						},
						{
							title: 'Additional Cost',
							team: '',
							miles: '',
							hours: '',
							pricePer: '',
							cost: '0',
						},
						{
							title: 'Custom Moving Team',
							team: '',
							miles: '',
							hours: '',
							pricePer: '',
							cost: '0',
						},
						{
							title: 'Custom Packing Team',
							team: '',
							miles: '',
							hours: '',
							pricePer: '',
							cost: '0',
						},
					],
					additionalTransportCost: 0,
					additionalCost: 0,
					costEstimateTotal: 0.0,
				},

				items: {
					Bedrooms: {
						items: [
							{
								title: 'AC (Window)',
								qty: '',
								weight: '140',
							},
							{
								title: 'Armoire',
								qty: '',
								weight: '210',
							},
							{
								title: 'Basket (Clothes)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Bed (Bunk Set (2))',
								qty: '',
								weight: '490',
							},
							{
								title: 'Bed (Double/Full)',
								qty: '',
								weight: '420',
							},
							{
								title: 'Bed (Hideaway)',
								qty: '',
								weight: '280',
							},
							{
								title: 'Bed (King)',
								qty: '',
								weight: '490',
							},
							{
								title: 'Bed (Queen)',
								qty: '',
								weight: '450',
							},
							{
								title: 'Bed (Rollaway)',
								qty: '',
								weight: '140',
							},
							{
								title: 'Bed (Single)',
								qty: '',
								weight: '280',
							},
							{
								title: 'Bed (Trundle)',
								qty: '',
								weight: '350',
							},
							{
								title: 'Bookshelves (Sect)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Bureau (Dresser)',
								qty: '',
								weight: '210',
							},
							{
								title: 'Cedar Chest',
								qty: '',
								weight: '105',
							},
							{
								title: 'Chaise Lounge',
								qty: '',
								weight: '175',
							},
							{
								title: 'Chest (Boudoir)',
								qty: '',
								weight: '70',
							},
							{
								title: 'Daybed',
								qty: '',
								weight: '210',
							},
							{
								title: 'Desk (Small)',
								qty: '',
								weight: '154',
							},
							{
								title: 'Drawers',
								qty: '',
								weight: '175',
							},
							{
								title: 'Dresser (Bench)',
								qty: '',
								weight: '21',
							},
							{
								title: 'Dresser (Double)',
								qty: '',
								weight: '350',
							},
							{
								title: 'Lamp (Floor/Pole)',
								qty: '',
								weight: '21',
							},
							{
								title: 'Night Table',
								qty: '',
								weight: '35',
							},
							{
								title: 'Rug (Large/Pad)',
								qty: '',
								weight: '70',
							},
							{
								title: 'Rug (Small/Pad)',
								qty: '',
								weight: '21',
							},
							{
								title: 'Valet',
								qty: '',
								weight: '21',
							},
							{
								title: 'Vanity Dresser',
								qty: '',
								weight: '140',
							},
							{
								title: 'Wardrobe Cartons/Clothes',
								qty: '',
								weight: '45',
							},
							{
								title: 'Wardrobe (Large)',
								qty: '',
								weight: '280',
							},
							{
								title: 'Wardrobe (Small)',
								qty: '',
								weight: '140',
							},
							{
								title: 'Water Bed',
								qty: '',
								weight: '420',
							},
							{
								title: 'Custom Items',
								itemDescription: '',
								qty: '',
								weight: '',
							},
						],
						totalWeight: '',
						totalQty: '',
						imageUrl: bedroom,
					},
					'Dining Room': {
						items: [
							{
								title: 'Bench',
								qty: '',
								weight: '70',
							},
							{
								title: 'Buffet',
								qty: '',
								weight: '210',
							},
							{
								title: 'Cabinet (China)',
								qty: '',
								weight: '175',
							},
							{
								title: 'Cabinet (Corner)',
								qty: '',
								weight: '140',
							},
							{
								title: 'Cabinet (Curio)',
								qty: '',
								weight: '105',
							},
							{
								title: 'Chair (Dining)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Hutch (Bottom)',
								qty: '',
								weight: '140',
							},
							{
								title: 'Hutch (Top)',
								qty: '',
								weight: '210',
							},
							{
								title: 'Rug (Large/Pad)',
								qty: '',
								weight: '70',
							},
							{
								title: 'Rug (Small/Pad)',
								qty: '',
								weight: '210',
							},
							{
								title: 'Server',
								qty: '',
								weight: '105',
							},
							{
								title: 'Table (Dining)',
								qty: '',
								weight: '210',
							},
							{
								title: 'Tea Cart',
								qty: '',
								weight: '70',
							},
							{
								title: 'Custom Items',
								itemDescription: '',
								qty: '',
								weight: '',
							},
						],
						totalWeight: '',
						totalQty: '',
						imageUrl: diningRoom,
					},
					Garage: {
						items: [
							{
								title: 'Ash/Trash Can',
								qty: '',
								weight: '49',
							},
							{
								title: 'Barbell Set (Small)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Bicycle',
								qty: '',
								weight: '35',
							},
							{
								title: 'Bowling Ball/Bag',
								qty: '',
								weight: '14',
							},
							{
								title: 'Cabinet (File)',
								qty: '',
								weight: '140',
							},
							{
								title: 'Car Carrier',
								qty: '',
								weight: '70',
							},
							{
								title: 'Card Table',
								qty: '',
								weight: '7',
							},
							{
								title: 'Chair (Folding)',
								qty: '',
								weight: '7',
							},
							{
								title: 'Cot (Folding)',
								qty: '',
								weight: '70',
							},
							{
								title: 'Dehumidifier',
								qty: '',
								weight: '70',
							},
							{
								title: 'Exercise Bike',
								qty: '',
								weight: '105',
							},
							{
								title: 'Fan',
								qty: '',
								weight: '35',
							},
							{
								title: 'Floor Polisher',
								qty: '',
								weight: '21',
							},
							{
								title: 'Footlocker/Trunk',
								qty: '',
								weight: '35',
							},
							{
								title: 'Freezer (15 CU or Less)',
								qty: '',
								weight: '315',
							},
							{
								title: 'Freezer (16 CU or More)',
								qty: '',
								weight: '420',
							},
							{
								title: 'Golf Bag',
								qty: '',
								weight: '14',
							},
							{
								title: 'Heater (Gas/Electric)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Humidifier',
								qty: '',
								weight: '35',
							},
							{
								title: 'Metal Shelves/Section',
								qty: '',
								weight: '35',
							},
							{
								title: 'Mini-Refrigerator',
								qty: '',
								weight: '70',
							},
							{
								title: 'Moped',
								qty: '',
								weight: '105',
							},
							{
								title: 'Motor (Electric)',
								qty: '',
								weight: '7',
							},
							{
								title: 'Movie Screen',
								qty: '',
								weight: '7',
							},
							{
								title: 'Movie/Slide Projector',
								qty: '',
								weight: '7',
							},
							{
								title: 'Ping Pong Table',
								qty: '',
								weight: '140',
							},
							{
								title: 'Pool Table',
								qty: '',
								weight: '280',
							},
							{
								title: 'Power Tools',
								qty: '',
								weight: '140',
							},
							{
								title: 'Rowing Machine',
								qty: '',
								weight: '70',
							},
							{
								title: 'Saw Horse',
								qty: '',
								weight: '35',
							},
							{
								title: 'Scooter',
								qty: '',
								weight: '35',
							},
							{
								title: 'Sewing Machine',
								qty: '',
								weight: '70',
							},
							{
								title: 'Skis/Poles',
								qty: '',
								weight: '21',
							},
							{
								title: 'Sled',
								qty: '',
								weight: '14',
							},
							{
								title: 'Sleeping Bag',
								qty: '',
								weight: '7',
							},
							{
								title: 'Step Ladder',
								qty: '',
								weight: '35',
							},
							{
								title: 'Suitcase',
								qty: '',
								weight: '35',
							},
							{
								title: 'Table (Utility)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Tackle Box',
								qty: '',
								weight: '7',
							},
							{
								title: 'Tool Chest',
								qty: '',
								weight: '70',
							},
							{
								title: 'Treadmill',
								qty: '',
								weight: '50',
							},
							{
								title: 'Tricycle',
								qty: '',
								weight: '35',
							},
							{
								title: 'Vacuum Cleaner',
								qty: '',
								weight: '35',
							},
							{
								title: "Wagon (Child's)",
								qty: '',
								weight: '35',
							},
							{
								title: 'Weight Bench',
								qty: '',
								weight: '105',
							},
							{
								title: 'Work Bench',
								qty: '',
								weight: '140',
							},
							{
								title: 'Work Mate',
								qty: '',
								weight: '70',
							},
							{
								title: 'Custom Items',
								itemDescription: '',
								qty: '',
								weight: '',
							},
						],
						totalWeight: '',
						totalQty: '',
						imageUrl: garage,
					},
					Kitchen: {
						items: [
							{
								title: 'Breakfast Set (Chairs)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Breakfast Set (Table)',
								qty: '',
								weight: '70',
							},
							{
								title: 'Bulletin Board',
								qty: '',
								weight: '7',
							},
							{
								title: 'Chair (High)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Dish Set',
								qty: '',
								weight: '70',
							},
							{
								title: 'Dishwasher',
								qty: '',
								weight: '140',
							},
							{
								title: 'Kitchen Cabinet',
								qty: '',
								weight: '210',
							},
							{
								title: 'Metal Cart',
								qty: '',
								weight: '35',
							},
							{
								title: 'Microwave',
								qty: '',
								weight: '70',
							},
							{
								title: 'Microwave Cart',
								qty: '',
								weight: '70',
							},
							{
								title: 'Pots and Pans',
								qty: '',
								weight: '35',
							},
							{
								title: 'Range',
								qty: '',
								weight: '210',
							},
							{
								title: 'Refrigerator (10 cu. ft. or Less)',
								qty: '',
								weight: '315',
							},
							{
								title: 'Refrigerator (11 cu. ft. or More)',
								qty: '',
								weight: '420',
							},
							{
								title: 'Roaster',
								qty: '',
								weight: '35',
							},
							{
								title: 'Serving Cart',
								qty: '',
								weight: '105',
							},
							{
								title: 'Serving Dishes',
								qty: '',
								weight: '5',
							},
							{
								title: 'Silverware',
								qty: '',
								weight: '35',
							},

							{
								title: 'Stand Mixer',
								qty: '',
								weight: '20',
							},
							{
								title: 'Stool',
								qty: '',
								weight: '21',
							},
							{
								title: 'Utility Cabinet',
								qty: '',
								weight: '70',
							},

							{
								title: 'Custom Items',
								itemDescription: '',
								qty: '',
								weight: '',
							},
						],
						totalWeight: '',
						totalQty: '',
						imageUrl: kitchen,
					},
					Laundry: {
						items: [
							{
								title: 'Basket (Clothes)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Clothes Dry Rack',
								qty: '',
								weight: '35',
							},
							{
								title: 'Clothes Hamper',
								qty: '',
								weight: '35',
							},
							{
								title: 'Dryer',
								qty: '',
								weight: '175',
							},
							{
								title: 'Ironing Board',
								qty: '',
								weight: '21',
							},
							{
								title: 'Rug (Small/Pad)',
								qty: '',
								weight: '21',
							},
							{
								title: 'Washing Machine',
								qty: '',
								weight: '175',
							},

							{
								title: 'Custom Items',
								itemDescription: '',
								qty: '',
								weight: '',
							},
						],
						totalWeight: '',
						totalQty: '',
						imageUrl: laundry,
					},
					'Living / Family Rooms': {
						items: [
							{
								title: 'Bar Cart',
								qty: '',
								weight: '105',
							},
							{
								title: 'Bench (Piano)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Bird Cage/Stand',
								qty: '',
								weight: '35',
							},
							{
								title: 'Book Case (Small)',
								qty: '',
								weight: '70',
							},
							{
								title: 'Book Case/Wall Unit',
								qty: '',
								weight: '140',
							},
							{
								title: 'Books',
								qty: '',
								weight: '45',
							},
							{
								title: 'Bookshelves (Sect)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Chair (Arm)',
								qty: '',
								weight: '70',
							},
							{
								title: 'Chair (Occas)',
								qty: '',
								weight: '105',
							},
							{
								title: 'Chair (Overstuffed)',
								qty: '',
								weight: '175',
							},
							{
								title: 'Chair (Straight)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Clock (Grandfather)',
								qty: '',
								weight: '140',
							},
							{
								title: 'Desk (Small)',
								qty: '',
								weight: '154',
							},
							{
								title: 'Fireplace Equipment',
								qty: '',
								weight: '35',
							},
							{
								title: 'Fish Tank',
								qty: '',
								weight: '105',
							},
							{
								title: 'Footstool',
								qty: '',
								weight: '14',
							},
							{
								title: 'Lamp (Floor/Pole)',
								qty: '',
								weight: '21',
							},
							{
								title: 'Magazine Rack',
								qty: '',
								weight: '14',
							},
							{
								title: 'Piano (Baby Grand)',
								qty: '',
								weight: '490',
							},
							{
								title: 'Piano (Parlor Grand)',
								qty: '',
								weight: '560',
							},
							{
								title: 'Piano (Spinet)',
								qty: '',
								weight: '420',
							},
							{
								title: 'Pictures',
								qty: '',
								weight: '25',
							},
							{
								title: 'Radio (Table)',
								qty: '',
								weight: '14',
							},
							{
								title: 'Record Player',
								qty: '',
								weight: '14',
							},
							{
								title: 'Rug (Large/Pad)',
								qty: '',
								weight: '70',
							},
							{
								title: 'Rug (Small/Pad)',
								qty: '',
								weight: '21',
							},
							{
								title: 'Sofa (2 Cushion)',
								qty: '',
								weight: '245',
							},
							{
								title: 'Sofa (3 Cushion)',
								qty: '',
								weight: '315',
							},
							{
								title: 'Sofa (4 Cushion)',
								qty: '',
								weight: '420',
							},
							{
								title: 'Sofa (Section Per)',
								qty: '',
								weight: '210',
							},
							{
								title: 'Speaker (Stereo)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Studio (Hidebed)',
								qty: '',
								weight: '350',
							},
							{
								title: 'TV Big Screen',
								qty: '',
								weight: '100',
							},
							{
								title: 'TV Console',
								qty: '',
								weight: '105',
							},
							{
								title: 'TV Stand',
								qty: '',
								weight: '35',
							},
							{
								title: 'TV Tray Set',
								qty: '',
								weight: '21',
							},
							{
								title: 'Table (Coffee/End)',
								qty: '',
								weight: '35',
							},
							{
								title: 'Table (Dropleaf)',
								qty: '',
								weight: '84',
							},
							{
								title: 'Video Game Set',
								qty: '',
								weight: '35',
							},
							{
								title: 'Wall Art',
								qty: '',
								weight: '15',
							},
							{
								title: 'Wine Rack',
								qty: '',
								weight: '35',
							},

							{
								title: 'Custom Items',
								itemDescription: '',
								qty: '',
								weight: '',
							},
						],
						totalWeight: '',
						totalQty: '',
						imageUrl: livingRoom,
					},
					Nursery: {
						items: [
							{
								title: 'Baby Carriage',
								qty: '',
								weight: '70',
							},

							{ title: 'Basket (Plastic)', qty: '', weight: '21' },
							{ title: 'Bassinet', qty: '', weight: '35' },
							{ title: 'Bed (Youth)', qty: '', weight: '70' },

							{ title: 'Car Seat', qty: '', weight: '14' },

							{ title: "Chair (Child's)", qty: '', weight: '21' },

							{ title: 'Chair (Rocker)', qty: '', weight: '84' },

							{ title: 'Changing Table', qty: '', weight: '70' },

							{ title: 'Chest', qty: '', weight: '91' },

							{ title: 'Chest (Toy)', qty: '', weight: '35' },

							{ title: 'Crib (Baby)', qty: '', weight: '70' },

							{ title: 'Doll House (Small)', qty: '', weight: '14' },

							{ title: 'Play House', qty: '', weight: '70' },

							{ title: 'Play Pen', qty: '', weight: '70' },
							{ title: 'Rug (Large/Pad)', qty: '', weight: '70' },

							{ title: 'Rug (Small/Pad)', qty: '', weight: '21' },

							{ title: 'Stroller', qty: '', weight: '35' },

							{ title: "Table (Child's)", qty: '', weight: '35' },

							{
								title: 'Custom Items',
								itemDescription: '',
								qty: '',
								weight: '',
							},
						],
						totalWeight: '',
						totalQty: '',
						imageUrl: nursery,
					},
					Office: {
						items: [
							{ title: 'Books', qty: '', weight: '45' },

							{ title: 'Bookshelves (Sect)', qty: '', weight: '35' },

							{ title: 'Chair (Straight)', qty: '', weight: '35' },

							{ title: 'Computer and Accessories', qty: '', weight: '35' },

							{ title: 'Desk (Office)', qty: '', weight: '210' },

							{ title: 'Desk (Secretary)', qty: '', weight: '245' },
							{ title: 'Home Computer', qty: '', weight: '30' },

							{ title: 'Printer/Keyboard', qty: '', weight: '5' },
							{ title: 'Waste Paper Basket', qty: '', weight: '2' },
							{
								title: 'Custom Items',
								itemDescription: '',
								qty: '',
								weight: '',
							},
						],
						totalWeight: '',
						totalQty: '',
						imageUrl: office,
					},

					'Patio & Shed': {
						items: [
							{ title: 'Bird Bath', qty: '', weight: '35' },
							{ title: 'Camp Stove', qty: '', weight: '14' },
							{ title: 'Chair (Outdoor)', qty: '', weight: '70' },
							{ title: "Child's Pool", qty: '', weight: '84' },
							{ title: 'Dog House', qty: '', weight: '70' },
							{ title: 'Garden Hose/Tools', qty: '', weight: '70' },
							{ title: 'Glider/Settee', qty: '', weight: '140' },
							{ title: 'Grill', qty: '', weight: '70' },
							{ title: 'Hand truck/Dolly', qty: '', weight: '14' },
							{ title: 'Ladder (Extension)', qty: '', weight: '70' },
							{ title: 'Lawn Edger', qty: '', weight: '35' },
							{ title: 'Lawn Mower (Hand)', qty: '', weight: '35' },
							{ title: 'Lawn Mower (Power)', qty: '', weight: '105' },
							{ title: 'Lawn Mower (Riding)', qty: '', weight: '245' },
							{ title: 'Leaf Sweeper', qty: '', weight: '35' },
							{ title: 'Outdoor Slide', qty: '', weight: '70' },
							{ title: 'Outdoor Swings', qty: '', weight: '105' },
							{ title: 'Picnic Bench', qty: '', weight: '35' },
							{ title: 'Picnic Table', qty: '', weight: '140' },
							{ title: 'Plant Stand', qty: '', weight: '70' },
							{ title: 'Rocker (Swing)', qty: '', weight: '105' },
							{ title: 'Roller (Lawn)', qty: '', weight: '105' },
							{ title: 'Sand Box', qty: '', weight: '70' },
							{ title: 'Settee', qty: '', weight: '140' },
							{ title: 'Snow Blower', qty: '', weight: '105' },
							{ title: 'Spreader', qty: '', weight: '7' },
							{ title: 'TV Antenna', qty: '', weight: '35' },
							{ title: 'Table (Picnic)', qty: '', weight: '70' },
							{ title: 'Umbrella', qty: '', weight: '35' },
							{ title: 'Wheelbarrow', qty: '', weight: '56' },
							{
								title: 'Custom Items',
								itemDescription: '',
								qty: '',
								weight: '',
							},
						],
						totalWeight: '',
						totalQty: '',
						imageUrl: patio,
					},
				},
				grandTotal: 0,
				grandQty: 0,
				pdfLoader: false,
				emailLoader: false,
			}),
		);
		this.props.setTypeHandle();
	};

	handleClientInfo = (name, value) => {
		this.setState((prevState) => {
			return {
				clientInfo: { ...prevState.clientInfo, [name]: value },
			};
		});
	};

	handleMovingFromFields = (name, value, tree = 'movingFromDetails', subtree, thirdTree) => {
		// console.log(name, value, tree, subtree, thirdTree);
		if (typeof value === 'object') {
			this.setState((prevState) => {
				let newFloorArr = prevState[tree][subtree][thirdTree];
				// console.log(newFloorArr[value.index]);
				newFloorArr[value.index].value = value.checked;
				// console.log(newFloorArr);
				return {
					[tree]: {
						...prevState[tree],
						[subtree]: {
							...prevState[tree][subtree],
							[thirdTree]: [...newFloorArr],
						},
					},
				};
			});
		} else {
			if (subtree === undefined) {
				this.setState((prevState) => {
					return {
						[tree]: { ...prevState[tree], [name]: value },
					};
				});
			} else if (thirdTree === undefined) {
				this.setState((prevState) => {
					return {
						[tree]: {
							...prevState[tree],
							[subtree]: { ...prevState[tree][subtree], [name]: value },
						},
					};
				});
			} else {
				this.setState((prevState) => {
					return {
						[tree]: {
							...prevState[tree],
							[subtree]: {
								...prevState[tree][subtree],
								[thirdTree]: {
									...prevState[tree][subtree][thirdTree],
									[name]: value,
								},
							},
						},
					};
				});
			}
		}

		// console.log(subtree === undefined,name,value, this.state.movingFromDetails)
	};

	phoneChangeHandler = (values) => {
		// console.log(values);
		this.setState((prevState) => {
			return {
				clientInfo: { ...prevState.clientInfo, phoneNo: values.value },
			};
		});
	};

	phoneToChangeHandler = (values) => {
		this.setState((prevState) => {
			return {
				movingToDetails: {
					...prevState.movingToDetails,
					destination1: {
						...prevState.movingToDetails.destination1,
						phoneNumber: values.value,
					},
				},
			};
		});
	};

	itemsChangeHandlerConst = async (name, value, tree, i) => {
		let teamPrice = [0, 0, 125, 185, 245, 305, 365];
		console.log(name, value, tree, i);
		await this.setState((prevState) => {
			let newPackingsItemsArr = prevState[tree].items;

			console.log(newPackingsItemsArr[i].title);
			newPackingsItemsArr[i][name] = value;

			newPackingsItemsArr[i].hours = isNaN(parseFloat(newPackingsItemsArr[i].hours)) ? 1 : newPackingsItemsArr[i].hours;
			newPackingsItemsArr[i].miles = isNaN(parseFloat(newPackingsItemsArr[i].miles)) ? 1 : newPackingsItemsArr[i].miles;
			newPackingsItemsArr[i].pricePer = isNaN(parseFloat(newPackingsItemsArr[i].pricePer)) ? 1 : newPackingsItemsArr[i].pricePer;
			newPackingsItemsArr[i].cost = parseFloat(newPackingsItemsArr[i].hours) * parseFloat(newPackingsItemsArr[i].miles) * parseFloat(newPackingsItemsArr[i].pricePer);

			if (newPackingsItemsArr[i].title === 'Packing Supplies') {
				newPackingsItemsArr[i].cost = value;
			}
			if ((newPackingsItemsArr[i].title === 'Packing Labor' || newPackingsItemsArr[i].title === 'Moving (Labor)') && name === 'team') {
				newPackingsItemsArr[i].pricePer = teamPrice[value];
			}

			// debugger
			if (i === 1) {
				let percentage = (parseFloat(this.state.grandTotal / 100) * parseFloat(newPackingsItemsArr[i].pricePer) * 25) / 100;
				let amount = parseFloat(this.state.grandTotal / 100) * parseFloat(newPackingsItemsArr[i].pricePer);
				newPackingsItemsArr[i].cost = amount + percentage;
			}

			console.log(newPackingsItemsArr[i].cost);
			newPackingsItemsArr[i].hours = newPackingsItemsArr[i].hours === 1 ? '' : newPackingsItemsArr[i].hours;
			newPackingsItemsArr[i].miles = newPackingsItemsArr[i].miles === 1 ? '' : newPackingsItemsArr[i].miles;
			newPackingsItemsArr[i].pricePer = newPackingsItemsArr[i].pricePer === 1 ? '' : newPackingsItemsArr[i].pricePer;
			newPackingsItemsArr[i].cost = newPackingsItemsArr[i].cost === 1 ? '0' : parseFloat(newPackingsItemsArr[i].cost).toFixed(2);
			console.log(newPackingsItemsArr);
			if (newPackingsItemsArr[i].title === 'Packing Supplies') {
				newPackingsItemsArr[i].cost = value;
			}
			return {
				[tree]: { ...prevState[tree], items: [...newPackingsItemsArr] },
			};
		});

		let totalPrice = 0;
		this.state.costEstimate.items.forEach((item) => {
			if (item.cost > 0) {
				totalPrice += parseFloat(item.cost);
			}
		});
		this.setState((prevState) => {
			return {
				costEstimate: {
					...prevState.costEstimate,
					costEstimateTotal: totalPrice + parseFloat(prevState.costEstimate.additionalTransportCost) + parseFloat(prevState.costEstimate.additionalCost) - this.state.packingAndPackingSupplies.totalPrice,
				},
			};
		});

		if (this.state.costEstimate.items[i].title === 'Packing Supplies') {
			if (parseFloat(this.state.costEstimate.items[i].cost) > 0) {
				this.setState((prevState) => {
					return {
						costEstimate: {
							...prevState.costEstimate,
							costEstimateTotal: totalPrice + parseFloat(prevState.costEstimate.additionalTransportCost) + parseFloat(prevState.costEstimate.additionalCost) - this.state.packingAndPackingSupplies.totalPrice,
						},
					};
				});
			}
		}
	};

	handleSupplyOnBlurr(name, value, tree, i) {
		if (parseFloat(this.state.costEstimate.items[i].cost) > 0) {
		} else {
			this.setState((prevState) => {
				let items = [...prevState.costEstimate.items];
				items[i] = {
					...prevState.costEstimate.items[i],
					cost: this.state.packingAndPackingSupplies.totalPrice,
				};
				return {
					costEstimate: {
						...prevState.costEstimate,
						items: items,
					},
				};
			});
			let totalPrice = 0;
			this.state.costEstimate.items.forEach((item) => {
				if (item.cost > 0) {
					totalPrice += parseFloat(item.cost);
				}
			});
			console.log(totalPrice);
			this.setState((prevState) => {
				console.log(prevState);
				return {
					costEstimate: {
						...prevState.costEstimate,
						costEstimateTotal: totalPrice + parseFloat(prevState.costEstimate.additionalTransportCost) + parseFloat(prevState.costEstimate.additionalCost),
					},
				};
			});
		}
	}
	handleAdditionalTransportCostChange = async (value) => {
		let totalPrice = 0;
		this.state.costEstimate.items.forEach((item) => {
			if (item.cost > 0) {
				totalPrice += parseFloat(item.cost);
			}
		});
		totalPrice += parseFloat(this.state.costEstimate.additionalCost);
		this.setState((prevState) => {
			return {
				costEstimate: {
					...prevState.costEstimate,
					additionalTransportCost: isNaN(value) ? 0 : value,
					costEstimateTotal: isNaN(totalPrice + parseFloat(value)) ? totalPrice : totalPrice + parseFloat(value),
				},
			};
		});
	};
	handleAdditionalCostChange = async (value) => {
		let totalPrice = 0;
		this.state.costEstimate.items.forEach((item) => {
			if (item.cost > 0) {
				totalPrice += parseFloat(item.cost);
			}
		});
		totalPrice += parseFloat(this.state.costEstimate.additionalTransportCost);
		this.setState((prevState) => {
			return {
				costEstimate: {
					...prevState.costEstimate,
					additionalCost: isNaN(value) ? 0 : value,
					costEstimateTotal: isNaN(totalPrice + parseFloat(value)) ? totalPrice : totalPrice + parseFloat(value),
				},
			};
		});
	};
	itemsChangeHandler = async (name, value, tree, i) => {
		await this.setState((prevState) => {
			let newPackingsItemsArr = prevState[tree].items;
			// console.log(newPackingsItemsArr[i]);
			newPackingsItemsArr[i][name] = value;
			newPackingsItemsArr[i].cost = parseFloat(newPackingsItemsArr[i].price * value).toFixed(2);
			// console.log(newPackingsItemsArr);
			return {
				[tree]: { ...prevState[tree], items: [...newPackingsItemsArr] },
			};
		});

		let totalPrice = 0;
		let totalSupplies = 0;
		this.state.packingAndPackingSupplies.items.forEach((item) => {
			if (item.cost > 0) {
				totalPrice += parseFloat(item.cost);
			}

			if (item.qty > 0) {
				totalSupplies += parseFloat(item.qty);
			}
		});
		this.setState((prevState) => {
			return {
				packingAndPackingSupplies: {
					...prevState.packingAndPackingSupplies,
					totalPrice: totalPrice.toFixed(2),
					totalSupplies: totalSupplies.toFixed(2),
				},
			};
		});
	};
	onPackingPriceChange = async (value) => {
		await this.setState((prevState) => {
			let newPackingsItemsArr = this.state.packingAndPackingSupplies.items;
			newPackingsItemsArr[9]['price'] = parseFloat(value);
			// console.log(newPackingsItemsArr);
			return {
				packingAndPackingSupplies: {
					...prevState.packingAndPackingSupplies,
					items: [...newPackingsItemsArr],
				},
			};
		});
	};
	itemChangeHandlerCustom = async (value, tree, i) => {
		await this.setState((prevState) => {
			let newItemArr = prevState.items[tree].items;
			newItemArr[i].qty = value;
			return {
				...prevState,
				items: {
					...prevState.items,
					[tree]: { ...prevState.items[tree], items: newItemArr },
				},
			};
		});

		// console.log(this.state.items)

		let totalQty = 0;
		let totalWeight = 0;

		this.state.items[tree].items.forEach((item) => {
			if (item.qty > 0) {
				totalWeight += parseFloat(item.qty) * parseFloat(item.weight);
			}
			if (item.qty > 0) {
				totalQty += parseFloat(item.qty);
			}
		});

		// console.log(totalQty, totalWeight)
		this.setState((prevState) => {
			return {
				...prevState,
				items: {
					...prevState.items,
					[tree]: {
						...prevState.items[tree],
						totalWeight: totalWeight,
						totalQty: totalQty,
					},
				},
			};
		});

		let grandTotal = 0;
		let grandQty = 0;
		Object.keys(this.state.items).forEach((keys) => {
			this.state.items[keys].items.forEach((item) => {
				if (item.qty > 0) {
					grandTotal += parseFloat(item.qty) * parseFloat(item.weight);
				}
				if (item.qty > 0) {
					grandQty += parseFloat(item.qty);
				}
			});
		});

		this.setState((prevState) => {
			return {
				grandTotal: grandTotal,
				grandQty: grandQty,
			};
		});
	};

	customeInputHandler = async (name, value, tree, i) => {
		await this.setState((prevState) => {
			let newItemArr = prevState.items[tree].items;
			newItemArr[i][name] = value;
			return {
				...prevState,
				items: {
					...prevState.items,
					[tree]: { ...prevState.items[tree], items: newItemArr },
				},
			};
		});

		// console.log(this.state.items)

		let totalQty = 0;
		let totalWeight = 0;

		this.state.items[tree].items.forEach((item) => {
			if (item.qty > 0) {
				totalWeight += parseFloat(item.qty) * parseFloat(item.weight);
			}
			if (item.qty > 0) {
				totalQty += parseFloat(item.qty);
			}
		});

		// console.log(totalQty, totalWeight)
		this.setState((prevState) => {
			return {
				...prevState,
				items: {
					...prevState.items,
					[tree]: {
						...prevState.items[tree],
						totalWeight: totalWeight,
						totalQty: totalQty,
					},
				},
			};
		});

		let grandTotal = 0;
		let grandQty = 0;
		Object.keys(this.state.items).forEach((keys) => {
			this.state.items[keys].items.forEach((item) => {
				if (item.qty > 0) {
					grandTotal += parseFloat(item.qty) * parseFloat(item.weight);
				}
				if (item.qty > 0) {
					grandQty += parseFloat(item.qty);
				}
			});
		});

		this.setState((prevState) => {
			return {
				grandTotal: grandTotal,
				grandQty: grandQty,
			};
		});
	};
	customInputBlurHandler = async (tree, i) => {
		let j = i;
		let newItem = {
			title: 'Custom Items',
			itemDescription: '',
			qty: '',
			weight: '',
		};

		// console.log(i, j+1)

		if (this.state.items[tree].items[j + 1]) {
			if (this.state.items[tree].items[j + 1].qty !== '' && this.state.items[tree].items[j + 1].weight !== '' && this.state.items[tree].items[j + 1].itemDescription !== '') {
				// console.log('----------------------')
			}
		} else {
			if (this.state.items[tree].items[i].qty !== '' && this.state.items[tree].items[i].itemDescription !== '' && this.state.items[tree].items[i].weight !== '') {
				await this.setState((prevState) => {
					let newArr = prevState.items[tree].items;
					if (prevState.items[tree].items[j + 1]) {
						return {
							...prevState,
							items: {
								...prevState.items,
								[tree]: { ...prevState.items[tree], items: newArr },
							},
						};
					} else {
						newArr.push(newItem);
						// console.log(newArr)
						return {
							...prevState,
							items: {
								...prevState.items,
								[tree]: { ...prevState.items[tree], items: newArr },
							},
						};
					}
				});
			}
		}

		// console.log(this.state.items)
	};

	handleConfirmClose = () => {
		this.setState({
			confirmReset: false,
		});
	};
	handlePrint = async () => {
		this.setState({ pdfLoader: true });
		let newState = { ...this.state };
		if (this.props.type && this.props.type === 'local') {
			delete newState.items;
		}
		axios
			.post(`http://api.terrymovingandstorageco.com/api/generate-pdf`, newState, {})
			// .post(`http://localhost:3001/api/generate-pdf`, newState, {})
			// .post(`https://terry-moving-apis.herokuapp.com/api/generate-pdf`, newState, {})

			.then(async (response) => {
				//console.log(response.data)
				window.location = `http://api.terrymovingandstorageco.com/uploads/${response.data.filename}`;
				// window.open(`http://api.terrymovingandstorageco.com/uploads/${response.data.filename}`, '_blank');
				// window.open(`http://localhost:3001/uploads/${response.data.filename}`, '_blank');
				// window.open(`https://terry-moving-apis.herok/uapp.com/uploads/${response.data.filename}`, '_blank');
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				this.setState({ pdfLoader: false });
			});
	};
	handleSendEmail = async () => {
		this.setState({ emailLoader: true });
		// console.log(this.state);
		let newState = { ...this.state };
		if (this.props.type && this.props.type === 'local') {
			// delete newState.items
		}
		axios
			.post(`http://api.terrymovingandstorageco.com/api/send-mail`, newState, {})
			// .post(`http://localhost:3001/api/send-mail`, newState, {})
			// .post(`https://terry-moving-apis.herokuapp.com/api/send-mail`, newState, {})
			.then(async (response) => {
				console.log(response.data);
				Swal.fire('Email sent successfully', '', 'success');
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				this.setState({ emailLoader: false });
			});
	};
	render() {
		const { classes } = this.props;
		const stepsContent = [
			<Fragment>
				<div className='container'>
					<div className='client-form-wrapper'>
						<div className='section-title text-left mb--50 mt--50'>
							<h3 className='title text-center'>Client Information</h3>
						</div>
						<form className='mb--40'>
							<div className='row'>
								<div className='col-lg-3 col-md-6 col-xs-6'>
									<label htmlFor='item01'>
										<input required type='text' name='customerName' id='item01' value={this.state.clientInfo.customerName} onChange={(e) => this.handleClientInfo(e.target.name, e.target.value)} placeholder='Customer Name' />
									</label>
								</div>

								<div className='col-lg-3 col-md-6 col-xs-6'>
									<label htmlFor='item03'>
										<NumberFormat name='phoneNo' value={this.state.clientInfo.phoneNo} displayType={'input'} onValueChange={this.phoneChangeHandler} format='(###) ### - ####' placeholder='Phone Number' />
										{/* <input required type='text' name='phoneNo' id='item03' value={this.state.clientInfo.phoneNo} onChange={e => this.handleClientInfo(e.target.name, e.target.value)} placeholder='Phone Number' /> */}
									</label>
								</div>

								<div className='col-lg-3 col-md-6 col-xs-6'>
									<label htmlFor='item04'>
										<input type='text' name='email' id='item04' value={this.state.clientInfo.email} onChange={(e) => this.handleClientInfo(e.target.name, e.target.value)} placeholder='Customer email' />
									</label>
								</div>
								<div className='col-lg-3 col-md-6 col-xs-6'>
									<label htmlFor='item04'>
										<input type='text' name='repEmail' id='item04' value={this.state.clientInfo.repEmail} onChange={(e) => this.handleClientInfo(e.target.name, e.target.value)} placeholder='Rep email' />
									</label>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Fragment>,
			<Fragment>
				<div className='from-form--1'>
					<div className='container'>
						<div className='row row--35 align-items-start'>
							<div className='col-lg-6 order-2 order-lg-1'>
								<div className='section-title text-left mb--20 mt--50'>
									<h3 className='title'>Moving From</h3>
								</div>
								<div className='form-wrapper'>
									<form>
										<h5>ORIGIN 1</h5>

										<div className='row'>
											<div className='col-lg-12 col-md-12 col-xs-12'>
												<p style={{ margin: '0', fontSize: '12px' }}>Moving Date</p>
												<label htmlFor='item01'>
													<input type='date' name='movingFromDate' id='item01' value={this.state.movingFromDetails.movingFromDate} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails')} placeholder='Moving Date' />
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item02'>
													<input
														type='text'
														name='streetAddress'
														id='item02'
														value={this.state.movingFromDetails.origin1.streetAddress}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'origin1')}
														placeholder='Street Address'
													/>
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item03'>
													<input type='text' name='city' id='item03' value={this.state.movingFromDetails.origin1.city} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'origin1')} placeholder='City' />
												</label>
											</div>
										</div>
										<div className='row mb--0'>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item04'>
													<input type='text' name='state' id='item04' value={this.state.movingFromDetails.origin1.state} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'origin1')} placeholder='Province/State' />
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item05'>
													<input type='text' name='zip' id='item05' value={this.state.movingFromDetails.origin1.zip} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'origin1')} placeholder='Zip' />
												</label>
											</div>
										</div>
										<div className='mt--20'></div>
										<h5>ORIGIN 2 (OPTIONAL)</h5>
										<div className='row'>
											<div className='col-lg-12 col-md-12 col-xs-12'>
												<label htmlFor='item06'>
													<input type='text' name='description' id='item06' value={this.state.movingFromDetails.origin2.description} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'origin2')} placeholder='Description' />
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item07'>
													<input
														type='text'
														name='streetAddress'
														id='item07'
														value={this.state.movingFromDetails.origin2.streetAddress}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'origin2')}
														placeholder='Street Address'
													/>
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item08'>
													<input type='text' name='city' id='item08' value={this.state.movingFromDetails.origin2.city} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'origin2')} placeholder='City' />
												</label>
											</div>
										</div>
										<div className='row mb--0'>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item09'>
													<input type='text' name='state' id='item09' value={this.state.movingFromDetails.origin2.state} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'origin2')} placeholder='Province/State' />
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item10'>
													<input type='text' name='zip' id='item10' value={this.state.movingFromDetails.origin2.zip} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'origin2')} placeholder='Zip' />
												</label>
											</div>
										</div>
										<div className='mt--20'></div>
										<div className='row mb--0'>
											<div className='col-lg-12 col-md-12 col-xs-12'>
												<label htmlFor='item11'>
													<textarea name='notes' id='item11' cols='30' rows='10' placeholder='Notes' onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails')}>
														{this.state.movingFromDetails.notes}
													</textarea>
												</label>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className='col-lg-6 order-2 order-lg-1'>
								<div className='section-title text-left mb--20 mt--50'>
									<h5 className='title'>ORIGIN INFORMATION: </h5>
								</div>
								<div className='form-wrapper'>
									<form>
										<div className='row'>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item12'>
													<input type='text' name='sqft' id='item12' value={this.state.movingFromDetails.originInformation.sqft} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'originInformation')} placeholder='Sq Ft' />
												</label>
												{/* {this.state.streetToMsg !== '' ? <label style={{ padding: '2vh', color: 'red' }}>{this.state.streetToMsg}</label> : null} */}
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item13'>
													<input
														type='text'
														name='bedrooms'
														id='item13'
														value={this.state.movingFromDetails.originInformation.bedrooms}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'originInformation')}
														placeholder='Bedrooms'
													/>
												</label>
											</div>
										</div>
										<div className='row mb--0'>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item14'>
													<input
														type='text'
														name='livingRooms'
														id='item14'
														value={this.state.movingFromDetails.originInformation.livingRooms}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'originInformation')}
														placeholder='Living Rooms'
													/>
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item15'>
													<input
														type='text'
														name='diningRooms'
														id='item15'
														value={this.state.movingFromDetails.originInformation.diningRooms}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'originInformation')}
														placeholder='Dining Rooms'
													/>
												</label>
											</div>
										</div>
										<div className='row mb--0'>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item016'>
													<input
														type='text'
														name='garage'
														id='item016'
														value={this.state.movingFromDetails.originInformation.garage}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'originInformation')}
														placeholder='Garge'
													/>
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item017'>
													<input type='text' name='patio' id='item017' value={this.state.movingFromDetails.originInformation.patio} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'originInformation')} placeholder='Patio' />
												</label>
											</div>
										</div>

										<div className='row mb--0'>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item016'>
													<input
														type='text'
														name='offices'
														id='item016'
														value={this.state.movingFromDetails.originInformation.offices}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'originInformation')}
														placeholder='Office(s)'
													/>
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item017'>
													<input
														type='text'
														name='bonusRooms'
														id='item017'
														value={this.state.movingFromDetails.originInformation.bonusRooms}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'originInformation')}
														placeholder='Bonus Rooms'
													/>
												</label>
											</div>
											<div className='col-lg-12 col-md-6 col-xs-6'>
												<label htmlFor='item017'>
													<input
														type='text'
														name='familyRooms'
														id='item017'
														value={this.state.movingFromDetails.originInformation.familyRooms}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails', 'originInformation')}
														placeholder='Family Rooms'
													/>
												</label>
											</div>
										</div>

										{this.state.movingFromDetails.originInformation.floors.map((floor, i) => (
											<div key={floor.title} className='row  mt--10 mb--10'>
												<div className='col-lg-12 mb-20'>
													<Form.Check type='checkbox' id={`check-api-${floor.title}`}>
														<Form.Check.Label>{`${floor.title}`}</Form.Check.Label>
														<Form.Check.Input name={floor.title} type='checkbox' onChange={(e) => this.handleMovingFromFields(e.target.name, { checked: e.target.checked, index: i }, 'movingFromDetails', 'originInformation', 'floors')} isValid checked={floor.value} />
													</Form.Check>
												</div>
											</div>
										))}
										<div className='from-from-appliance mt--50 mb--10'>
											<h5>Appliances</h5>
											<div className='row  mt--10 mb--10'>
												{this.state.movingFromDetails.originInformation.appliances.map((appliance, i) => (
													<div key={appliance.title} className='col-lg-6 mb-20'>
														<Form.Check type='checkbox' id={`check-api-${appliance.title}`}>
															<Form.Check.Label>{`${appliance.title}`}</Form.Check.Label>
															<Form.Check.Input
																name={appliance.title}
																type='checkbox'
																onChange={(e) => this.handleMovingFromFields(e.target.name, { checked: e.target.checked, index: i }, 'movingFromDetails', 'originInformation', 'appliances')}
																isValid
																checked={appliance.value}
															/>
														</Form.Check>
													</div>
												))}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className='row row--35 align-items-start'></div>
					</div>
				</div>
			</Fragment>,
			<Fragment>
				<div className='from-form--1'>
					<div className='container'>
						<div className='row row--35 align-items-start'>
							<div className='col-lg-6 order-2 order-lg-1'>
								<div className='section-title text-left mb--20 mt--50'>
									<h3 className='title'>Moving To</h3>
								</div>
								<div className='form-wrapper'>
									<form>
										<h5>DESTINATION 1</h5>
										<div className='row'>
											<div className='col-lg-12 col-md-12 col-xs-12'>
												<p style={{ margin: '0', fontSize: '12px' }}>Delievery Date</p>
												<label htmlFor='item01'>
													<input type='date' name='delieveryDate' id='item01' value={this.state.movingToDetails.delieveryDate} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails')} placeholder='Moving Date' />
												</label>
												<label className={classes.TBD__wrapper}>
													<input
														className={classes.TBD}
														type='checkbox'
														name='tbd'
														id='item001'
														onChange={(e) => {
															const { name, value } = e.target;
															this.setState((prevState) => {
																return {
																	movingToDetails: { ...prevState['movingToDetails'], [name]: value },
																};
															});
														}}
													/>{' '}
													TBD
												</label>
											</div>
											<div className='col-lg-6 col-md-12 col-xs-12'>
												<label htmlFor='item02'>
													<input
														type='text'
														name='contactName'
														id='item02'
														value={this.state.movingToDetails.destination1.contactName}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination1')}
														placeholder='Contact Name'
													/>
												</label>
											</div>
											<div className='col-lg-6 col-md-12 col-xs-12'>
												<label htmlFor='item03'>
													<NumberFormat name='phoneNumber' value={this.state.movingToDetails.destination1.phoneNumber} displayType={'input'} onValueChange={this.phoneToChangeHandler} format='(###) ### - ####' placeholder='Phone Number' />
													{/* <input type='text' name='phone' id='item03' value={this.state.movingFromDetails.movingFromDate} onChange={e => this.handleMovingFromFields(e.target.name, e.target.value, 'movingFromDetails')} placeholder='Phone' /> */}
												</label>
											</div>
											<div className='col-lg-12 col-md-12 col-xs-12'>
												<label htmlFor='item04'>
													<input
														type='text'
														name='description'
														id='item04'
														value={this.state.movingToDetails.destination1.description}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination1')}
														placeholder='Description'
													/>
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item05'>
													<input
														type='text'
														name='streetAddress'
														id='item05'
														value={this.state.movingToDetails.destination1.streetAddress}
														onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination1')}
														placeholder='Street Address'
													/>
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item06'>
													<input type='text' name='city' id='item07' value={this.state.movingToDetails.destination1.city} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination1')} placeholder='City' />
												</label>
											</div>
										</div>
										<div className='row mb--0'>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item04'>
													<input type='text' name='state' id='item04' value={this.state.movingToDetails.destination1.state} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination1')} placeholder='Province/State' />
												</label>
											</div>
											<div className='col-lg-6 col-md-6 col-xs-6'>
												<label htmlFor='item05'>
													<input type='text' name='zip' id='item05' value={this.state.movingToDetails.destination1.zip} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination1')} placeholder='Zip' />
												</label>
											</div>
										</div>
										{this.state.movingToDetails.destination1.floors.map((floor, i) => (
											<div key={floor.title} className='row  mt--10 mb--10'>
												<div className='col-lg-12 mb-20'>
													<Form.Check type='checkbox' id={`destination1-${floor.title}`}>
														<Form.Check.Label>{`${floor.title}`}</Form.Check.Label>
														<Form.Check.Input name={floor.title} onChange={(e) => this.handleMovingFromFields(e.target.name, { checked: e.target.checked, index: i }, 'movingToDetails', 'destination1', 'floors')} type='checkbox' isValid />
													</Form.Check>
												</div>
											</div>
										))}
										<div className='mt--20'></div>
									</form>
								</div>
							</div>
							<div className='col-lg-6 order-2 order-lg-1'>
								<div className='section-title text-left mb--20 mt--50'></div>
								<div className='form-wrapper'>
									<h5 className='title'>DESTINATION 2 </h5>
									<div className='row'>
										<div className='col-lg-12 col-md-12 col-xs-12'>
											<label htmlFor='item06'>
												<input type='text' name='description' id='item06' value={this.state.movingToDetails.destination2.description} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination2')} placeholder='Description' />
											</label>
										</div>
										<div className='col-lg-6 col-md-6 col-xs-6'>
											<label htmlFor='item07'>
												<input
													type='text'
													name='streetAddress'
													id='item07'
													value={this.state.movingToDetails.destination2.streetAddress}
													onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination2')}
													placeholder='Street Address'
												/>
											</label>
										</div>
										<div className='col-lg-6 col-md-6 col-xs-6'>
											<label htmlFor='item08'>
												<input type='text' name='city' id='item08' value={this.state.movingToDetails.destination2.city} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination2')} placeholder='City' />
											</label>
										</div>
									</div>
									<div className='row mb--0'>
										<div className='col-lg-6 col-md-6 col-xs-6'>
											<label htmlFor='item09'>
												<input type='text' name='state' id='item09' value={this.state.movingToDetails.destination2.state} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination2')} placeholder='Province/State' />
											</label>
										</div>
										<div className='col-lg-6 col-md-6 col-xs-6'>
											<label htmlFor='item10'>
												<input type='text' name='zip' id='item10' value={this.state.movingToDetails.destination2.zip} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails', 'destination2')} placeholder='Zip' />
											</label>
										</div>
									</div>
									{this.state.movingToDetails.destination2.floors.map((floor, i) => (
										<div key={floor.title} className='row  mt--10 mb--10'>
											<div className='col-lg-12 mb-20'>
												<Form.Check type='checkbox' id={`destination2-${floor.title}`}>
													<Form.Check.Label>{`${floor.title}`}</Form.Check.Label>
													<Form.Check.Input name={floor.title} onChange={(e) => this.handleMovingFromFields(e.target.name, { checked: e.target.checked, index: i }, 'movingToDetails', 'destination2', 'floors')} type='checkbox' isValid />
												</Form.Check>
											</div>
										</div>
									))}
									<div className='mt--20'></div>
								</div>
							</div>
						</div>

						<div className='rcolp row row--35 align-items-start'></div>
						<div className='rcolp row mb--0'>
							<div className='col-lg-12 col-md-12 col-xs-12'>
								<label style={{ display: 'block' }} htmlFor='item11'>
									<textarea name='notes' id='item11' cols='30' rows='10' placeholder='Notes' onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'movingToDetails')}>
										{this.state.movingToDetails.notes}
									</textarea>
								</label>
							</div>
						</div>
					</div>
				</div>
			</Fragment>,
			<Fragment>
				<div className='packing-form-wrapper'>
					<div className='container'>
						<div className='section-title text-left mb--50 mt--50'>
							<h3 className='title text-center'>PACKING & PACKING SUPPLIES</h3>
						</div>
						<div className='rcolp row packing-date-box mb--10'>
							<div className='col-lg-4 col-md-12 col-xs-12'>
								<p style={{ margin: '0', fontSize: '12px' }}>Packing Date</p>
								<label style={{ display: 'block' }} htmlFor='item01'>
									<input type='date' name='packingDate' id='item01' value={this.state.packingAndPackingSupplies.packingDate} onChange={(e) => this.handleMovingFromFields(e.target.name, e.target.value, 'packingAndPackingSupplies')} placeholder='Moving Date' />
								</label>
							</div>
						</div>
						<Table className='itemsTable' responsive striped hover size='sm'>
							<thead>
								<tr>
									<th>Item</th>
									<th>Price Per</th>
									<th>Qty</th>
									<th>Cost</th>
								</tr>
							</thead>
							<tbody>
								{this.state.packingAndPackingSupplies.items.map((item, i) => (
									<tr key={i}>
										<td>{item.item}</td>
										<td>
											{item.item === 'Labor' ? (
												<input type='number' name='pricePer' value={item.price} onChange={(e) => this.onPackingPriceChange(e.target.value)} min={0} />
											) : (
												<input type='text' name='pricePer' disabled value={item.pricePer} onChange={(e) => this.itemsChangeHandler(e.target.name, e.target.value, 'packingAndPackingSupplies', i)} />
											)}
										</td>
										<td>
											<input type='text' name='qty' value={item.qty} onChange={(e) => this.itemsChangeHandler(e.target.name, e.target.value, 'packingAndPackingSupplies', i)} />
										</td>
										<td>
											<input type='text' name='cost' value={item.cost} disabled onChange={(e) => this.itemsChangeHandler(e.target.name, e.target.value, 'packingAndPackingSupplies', i)} />
										</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<th>TOTAL SUPPLIES:</th>
									<th></th>
									<th>{this.state.packingAndPackingSupplies.totalSupplies}</th>
									<th>$ {this.state.packingAndPackingSupplies.totalPrice}</th>
								</tr>
								<tr>
									<th>TOTAL LABOR:</th>
									<th></th>
									<th></th>
									<th></th>
								</tr>
							</tfoot>
						</Table>
					</div>
				</div>
			</Fragment>,
			<Fragment>
				<div className='cosnt-form-wrapper'>
					<div className='container'>
						<div className='section-title text-left mb--50 mt--50'>
							<h3 className='title text-center'>INVENTORY</h3>
						</div>
						{Object.keys(this.state.items).map((keys) => (
							<Accordion key={keys}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
									<Typography className={classes.heading}>{keys}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Container>
										<Row>
											<Col>
												<img src={this.state.items[keys].imageUrl} alt={keys} />
											</Col>

											<Col xs='5' sm='5'>
												<div style={{ textAlign: 'left' }} className={classes.ColumnTitle}>
													Items
												</div>
											</Col>
											<Col xs='3' sm='3'>
												<div className={classes.ColumnTitle}>Quantity</div>
											</Col>
											<Col xs='3' sm='3'>
												<div className={classes.ColumnTitle}>Weight(lbs)</div>
											</Col>
										</Row>
										{this.state.items[keys].items.map((item, id) => {
											// console.log(id ,this.state.items[keys].items.length - 1)
											// console.log(item.title !== 'Custom Items');
											if (item.title !== 'Custom Items') {
												return (
													<Row className='mt--10 mb--10' key={id}>
														<Col xs='1' sm='1'></Col>
														<Col xs='5' sm='5'>
															{item.title}
														</Col>
														<Col xs='3' sm='3'>
															<input type='number' min='0' value={item.qty} onChange={(e) => this.itemChangeHandlerCustom(e.target.value, keys, id)} placeholder='qty' style={{ textAlign: 'right', fontSize: '16px' }} />
														</Col>
														<Col style={{ textAlign: 'right' }} xs='3' sm='3'>
															{item.weight}
														</Col>
													</Row>
												);
											}
										})}

										<Row className='mt--20 mb--20'>
											<Col></Col>
											<Col xs='5' sm='5'>
												<div style={{ textAlign: 'left' }} className={classes.ColumnTitle}>
													Custom Item
												</div>
											</Col>
											<Col xs='3' sm='3'>
												<div className={classes.ColumnTitle}>Quantity</div>
											</Col>
											<Col xs='3' sm='3'>
												<div className={classes.ColumnTitle}>Weight(lbs)</div>
											</Col>
										</Row>
										{this.state.items[keys].items.map((item, id) => {
											if (item.title === 'Custom Items') {
												return (
													<Row key={id} className='mt--10 mb--10'>
														<Col></Col>
														<Col xs='5' sm='5'>
															<div style={{ textAlign: 'left' }} className={classes.ColumnTitleInput}>
																<input type='text' name='itemDescription' value={item.itemDescription} onChange={(e) => this.customeInputHandler(e.target.name, e.target.value, keys, id)} placeholder='Item Description' />
															</div>
														</Col>
														<Col xs='3' sm='3'>
															<div className={classes.ColumnTitleInput}>
																<input min='0' type='number' value={item.qty} onChange={(e) => this.customeInputHandler(e.target.name, e.target.value, keys, id)} name='qty' />
															</div>
														</Col>
														<Col xs='3' sm='3'>
															<div className={classes.ColumnTitleInput}>
																<input min='0' type='number' value={item.weight} onChange={(e) => this.customeInputHandler(e.target.name, e.target.value, keys, id)} name='weight' onBlur={(e) => this.customInputBlurHandler(keys, id)} />
															</div>
														</Col>
													</Row>
												);
											}
										})}
										<div className={classes.Result}>
											<Row className='mt--10 mb--10'>
												<Col></Col>
												<Col xs='5' sm='5'>
													<div style={{ textAlign: 'left' }} className={classes.ColumnTitleInput}>
														<span className={classes.TotalTxt}>{keys} TOTAL :</span>
													</div>
												</Col>
												<Col xs='3' sm='3'>
													<div className={classes.ColumnResult}>
														<span>{this.state.items[keys].totalQty}</span>
													</div>
												</Col>
												<Col xs='3' sm='3'>
													<div className={classes.ColumnResult}>
														<span>{this.state.items[keys].totalWeight}</span>
													</div>
												</Col>
											</Row>
										</div>
									</Container>
								</AccordionDetails>
							</Accordion>
						))}
					</div>
				</div>
				<div>
					<Container>
						<Row>
							<Col xs='6' sm='6'></Col>
							<Col xs='3' sm='3'>
								<strong>{this.state.grandQty}</strong>
							</Col>
							<Col xs='3' sm='3'>
								<strong>{this.state.grandTotal}</strong>
							</Col>
						</Row>
					</Container>
				</div>
			</Fragment>,
			<Fragment>
				<div className='cosnt-form-wrapper'>
					<div className='container'>
						<div className='section-title text-left mb--50 mt--50'>
							<h3 className='title text-center'>COST ESTIMATE</h3>
							<h4 className='title text-center'>
								<strong>Shipment Weight:</strong> {this.state.grandTotal}{' '}
							</h4>
						</div>
						<Table responsive className='packingitemsTable' striped hover size='sm'>
							<thead>
								<tr>
									<th>Product / Service</th>
									<th>Team</th>
									<th>Weight</th>
									<th>Hours</th>
									<th>Price Per</th>
									<th>Cost</th>
								</tr>
							</thead>
							<tbody>
								{/* Packing labor start */}
								<tr>
									<td>{this.state.costEstimate.items[0].title}</td>
									<td style={{ textAlign: 'center' }}>
										{/* x */}
										<select name='team' onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 0)}>
											<option value={0}>Please Select Men</option>
											{[2, 3, 4, 5, 6].map((item) => (
												<option key={item} value={item}>
													{item} Men
												</option>
											))}
										</select>
										{/* <input type='text' name='team' value={this.state.costEstimate.items[0].team} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 0)} /> */}
									</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td>
										<input type='text' name='hours' value={this.state.costEstimate.items[0].hours} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 0)} />
									</td>
									<td>
										<input type='text' name='pricePer' value={this.state.costEstimate.items[0].pricePer} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 0)} />
									</td>
									<td>
										<input type='text' name='cost' disabled value={this.state.costEstimate.items[0].cost} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 0)} />
									</td>
								</tr>
								{/* Packing labor end */}
								{/* Moving (Miles per 100 lbs) start */}
								<tr>
									<td>{this.state.costEstimate.items[1].title}</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>
										<input type='text' name='hours' value={parseFloat(this.state.grandTotal / 100)} disabled />
									</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td>
										<input type='text' name='pricePer' value={this.state.costEstimate.items[1].pricePer} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 1)} />
									</td>
									<td>
										<input type='text' name='cost' disabled value={this.state.costEstimate.items[1].cost} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 1)} />
									</td>
								</tr>
								{/* Moving (Miles per 100 lbs) end */}
								{/* Moving (Labor) start */}
								<tr>
									<td>{this.state.costEstimate.items[2].title}</td>
									<td style={{ textAlign: 'center' }}>
										{/* x */}
										<select name='team' onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 2)}>
											<option value={0}>Please Select Men</option>
											{[2, 3, 4, 5, 6].map((item) => (
												<option key={item} value={item}>
													{item} Men
												</option>
											))}
										</select>
										{/* <input type='text' name='team' value={this.state.costEstimate.items[2].team} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 2)} /> */}
									</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td>
										<input type='text' name='hours' value={this.state.costEstimate.items[2].hours} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 2)} />
									</td>
									<td>
										<input type='text' name='pricePer' value={this.state.costEstimate.items[2].pricePer} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 2)} />
									</td>
									<td>
										<input type='text' name='cost' disabled value={this.state.costEstimate.items[2].cost} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 2)} />
									</td>
								</tr>
								{/* Moving (Labor) end */}
								{/* Valuation (Value of goods shipped) start */}
								<tr>
									<td>{this.state.costEstimate.items[3].title}</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td>
										<input type='text' name='pricePer' value={this.state.costEstimate.items[3].pricePer} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 3)} />
									</td>
									<td>
										<input type='text' name='cost' disabled value={this.state.costEstimate.items[3].cost} />
									</td>
								</tr>
								{/* Valuation (Value of goods shipped) end */}
								<tr>
									<td>{this.state.costEstimate.items[4].title}</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td>
										<input
											type='text'
											name='cost'
											onBlur={(e) => this.handleSupplyOnBlurr(e.target.name, e.target.value, 'costEstimate', 4)}
											onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', 4)}
											value={this.state.costEstimate.items[4].cost}
										/>
									</td>
								</tr>
								<tr>
									<td>{this.state.costEstimate.items[5].title}</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td>
										<input type='text' name='cost' value={this.state.costEstimate.additionalTransportCost} onChange={(e) => this.handleAdditionalTransportCostChange(e.target.value)} />
									</td>
								</tr>
								<tr>
									<td>{this.state.costEstimate.items[6].title}</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td>
										<input type='text' name='cost' value={this.state.costEstimate.additionalCost} onChange={(e) => this.handleAdditionalCostChange(e.target.value)} />
									</td>
								</tr>
								<tr>
									<td>Custom Moving Team</td>
									<td style={{ textAlign: 'center' }}>
										<input type='text' name='team' value={this.state.costEstimate.items[this.state.costEstimate.items.length - 1].team} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', this.state.costEstimate.items.length - 1)} />
									</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>
										<input type='text' name='hours' value={this.state.costEstimate.items[this.state.costEstimate.items.length - 1].hours} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', this.state.costEstimate.items.length - 1)} />
									</td>
									<td style={{ textAlign: 'center' }}>
										<input
											type='text'
											name='pricePer'
											value={this.state.costEstimate.items[this.state.costEstimate.items.length - 1].pricePer}
											onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', this.state.costEstimate.items.length - 1)}
										/>
									</td>
									<td>
										<input type='text' name='cost' disabled value={this.state.costEstimate.items[this.state.costEstimate.items.length - 1].cost} onChange={(e) => this.handleAdditionalCostChange(e.target.value)} />
									</td>
								</tr>

								<tr>
									<td>Custom Packing Team</td>
									<td style={{ textAlign: 'center' }}>
										<input type='text' name='team' value={this.state.costEstimate.items[this.state.costEstimate.items.length - 2].team} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', this.state.costEstimate.items.length - 2)} />
									</td>
									<td style={{ textAlign: 'center' }}>x</td>
									<td style={{ textAlign: 'center' }}>
										<input type='text' name='hours' value={this.state.costEstimate.items[this.state.costEstimate.items.length - 2].hours} onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', this.state.costEstimate.items.length - 2)} />
									</td>
									<td style={{ textAlign: 'center' }}>
										<input
											type='text'
											name='pricePer'
											value={this.state.costEstimate.items[this.state.costEstimate.items.length - 2].pricePer}
											onChange={(e) => this.itemsChangeHandlerConst(e.target.name, e.target.value, 'costEstimate', this.state.costEstimate.items.length - 2)}
										/>
									</td>
									<td>
										<input type='text' name='cost' disabled value={this.state.costEstimate.items[this.state.costEstimate.items.length - 2].cost} onChange={(e) => this.handleAdditionalCostChange(e.target.value)} />
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<th>SPECIAL DISCOUNT </th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th>
										<input
											type='text'
											name='specialDiscount'
											placeholder='0'
											value={this.state.specialDiscount}
											onChange={(e) => {
												const value = e.target.value;
												if (value > parseFloat(this.state.costEstimate.costEstimateTotal) + parseFloat(this.state.packingAndPackingSupplies.totalPrice)) {
													return;
												}
												this.setState((prevState) => {
													return {
														...prevState,
														specialDiscount: value,
													};
												});
											}}
										/>
									</th>
								</tr>
								<tr>
									<th>TOTAL </th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									{this.state.specialDiscount > 0 ? (
										<th>${(parseFloat(this.state.costEstimate.costEstimateTotal) + parseFloat(this.state.packingAndPackingSupplies.totalPrice) - parseFloat(this.state.specialDiscount)).toFixed(2)}</th>
									) : (
										<th>${(parseFloat(this.state.costEstimate.costEstimateTotal) + parseFloat(this.state.packingAndPackingSupplies.totalPrice)).toFixed(2)}</th>
									)}
								</tr>
							</tfoot>
						</Table>
					</div>
				</div>
				<div className={classes.GrandTotal}>
					<Container>
						<Row>
							<Col lg='12' xs='12' sm='12'>
								<div className={classes.bottomRibbon}>
									<Button variant='contained' color='secondary' onClick={() => this.handlePrint()}>
										{this.state.pdfLoader ? 'Generating PDF...' : 'Print'}
									</Button>
									<input
										type='email'
										name='sendEmail'
										onChange={(e) => {
											const { name, value } = e.target;
											this.setState({
												[name]: value,
											});
										}}
										placeholder='Enter Receving Email'
										style={{ color: 'white' }}
										value={this.state.sendEmail}
									/>
									<Button variant='contained' color='secondary' onClick={() => this.handleSendEmail()} style={{ marginLeft: 10 }}>
										{this.state.emailLoader ? 'Sending Email...' : 'SEND EMAIL'}
									</Button>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</Fragment>,
		];

		let activeStep;

		if (this.state.activeStep === 0) {
			activeStep = (
				<Button
					disabled={this.state.clientInfo.customerName === '' || this.state.clientInfo.email === '' || this.state.clientInfo.phoneNo === '' || this.state.clientInfo.prefferedMovingDate === ''}
					className={['rn-btn', classes.btnBack].join(' ')}
					variant='contained'
					color='primary'
					onClick={this.handleNext}>
					{this.state.activeStep === this.state.steps.length - 1 ? 'Finish' : 'Next'}
				</Button>
			);
		} else if (this.state.activeStep === 1) {
			activeStep = (
				<Button
					disabled={this.state.movingFromDetails.movingFromDate === '' || this.state.movingFromDetails.origin1.city === '' || this.state.movingFromDetails.origin1.state === '' || this.state.movingFromDetails.origin1.zip === ''}
					className={['rn-btn', classes.btnBack].join(' ')}
					variant='contained'
					color='primary'
					onClick={this.handleNext}>
					{this.state.activeStep === this.state.steps.length - 1 ? 'Finish' : 'Next'}
				</Button>
			);
		} else if (this.state.activeStep === 2) {
			activeStep = (
				<Button
					// disabled={this.state.movingToDetails.destination1.city === '' || this.state.movingToDetails.destination1.state === '' || this.state.movingToDetails.destination1.zip === ''}
					className={['rn-btn', classes.btnBack].join(' ')}
					variant='contained'
					color='primary'
					onClick={this.handleNext}>
					{this.state.activeStep === this.state.steps.length - 1 ? 'Finish' : 'Next'}
				</Button>
			);
		} else if (this.state.activeStep === 3) {
			//disabled={this.state.packingAndPackingSupplies.packingDate === '' }
			activeStep = (
				<Button className={['rn-btn', classes.btnBack].join(' ')} variant='contained' color='primary' onClick={this.handleNext}>
					{this.state.activeStep === this.state.steps.length - 1 ? 'Finish' : 'Next'}
				</Button>
			);
		} else if (this.state.activeStep === 4) {
			activeStep = (
				<Button className={['rn-btn', classes.btnBack].join(' ')} variant='contained' color='primary' onClick={this.handleNext}>
					{this.state.activeStep === this.state.steps.length - 1 ? 'Finish' : 'Next'}
				</Button>
			);
		} else if (this.state.activeStep === 5) {
			activeStep = (
				<Button className={['rn-btn', classes.btnBack].join(' ')} variant='contained' color='primary' onClick={this.handleNext}>
					{this.state.activeStep === this.state.steps.length - 1 ? 'Finish' : 'Next'}
				</Button>
			);
		}
		return (
			<Fragment>
				<div className='stepper-form--1'>
					<div className='container'>
						<div className='rcolp row row--35 align-items-start'>
							<div className='col-lg-12 order-2 order-lg-1'>
								<Stepper activeStep={this.state.activeStep} alternativeLabel>
									{this.state.steps.map((label, index) => (
										<Step key={label}>
											{/* {console.log(this.state.activatedStep)} */}
											<StepLabel
												onClick={() => {
													if (this.state.activatedStep[index]) {
														this.setState({
															activeStep: index,
														});
													}
												}}>
												{label}
											</StepLabel>
										</Step>
									))}
								</Stepper>
								<div>
									{this.state.activeStep === this.state.steps.length ? (
										<div>
											<Typography className={classes.instructions}>All steps completed</Typography>
											<Button
												onClick={() => {
													this.setState({ confirmReset: true });
												}}>
												Reset
											</Button>
											<Dialog open={this.state.confirmReset} onClose={this.handleConfirmClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
												<DialogTitle id='alert-dialog-title'>{'Reset Moving Form'}</DialogTitle>
												<DialogContent>
													<DialogContentText id='alert-dialog-description'>Are You sure to reset form?</DialogContentText>
												</DialogContent>
												<DialogActions>
													<Button onClick={this.handleConfirmClose} color='secondary'>
														Cancel
													</Button>
													<Button onClick={this.handleReset} color='primary' autoFocus>
														Reset
													</Button>
												</DialogActions>
											</Dialog>
										</div>
									) : (
										<div>
											<div className={classes.instructions}>{this.props.type && this.props.type === 'local' ? stepsContent.filter((sc, id) => id !== 4)[this.state.activeStep] : stepsContent[this.state.activeStep]}</div>
											<div className='text-center mb--30 mt--30'>
												<Button onClick={() => this.props.setTypeHandle()} variant='contained' className={classes.backButton}>
													Cancel
												</Button>
												<Button
													onClick={() => {
														if (this.state.activeStep === 0) {
															this.props.setTypeHandle();
														} else {
															this.handleBack();
														}
													}}
													className={classes.backButton}>
													Back
												</Button>
												{activeStep}
											</div>
										</div>
									)}
								</div>
								{/* <div className='section-title text-left mb--50'>
								<h2 className='title'>Client Information</h2>
							</div>
							 */}
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
export default withRouter(withStyles(styles)(MovingFrom));
