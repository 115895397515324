import React, { Component } from 'react';
import './App.scss';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MovingContainer from './Views/Stepper/MovingFormContainer';
import TopHeader from './Layout/Header/Header';
import Footer from './Layout/Footer/Footer';

class App extends Component {
	render() {
		return (
			<BrowserRouter basename={'/'}>
				<div className='App'>
					<TopHeader />
					<Switch>
						<Route to='/submit-order' exact={true} component={MovingContainer} />
					</Switch>
					<Footer />
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
